import { atom, atomFamily } from 'recoil';
import {
  NO_ERROR_IN_TABLE,
  TLocationRecord,
} from '../../components/tables/EpFullFeaturedTable';
import { selector, selectorFamily } from 'recoil';
import {
  IEpSubsCompositeDBInvoiceValueInList,
  IEpSubsCompositeDBInvoiceValue,
  IEpSubsInvoiceList,
} from '../../models/';
import { genInvoiceData } from '../../services/epSubsInvoiceService';
import { ListState } from '../../components/tables/EpFullFeaturedTable';
import { basicFilterEnabledSearchListSelectorsFamily } from '..';
import { customerSelectors } from '../';
import { DEBUGGING } from 'src/config';
import { customerDetailEnabledAtomFamily } from '../customer/customerListPageStates';
import { getNoFilterOptionBySource } from 'src/components/navBars/EpSubsListNav/EpSubsListNav';
import { getDefaultFilterSearchParam } from 'src/components/navBars/EpSubsListNav/EpSubsListNavUtil';

// ************************************************ //
// Invoice List Latest Search Parameter States //
// ************************************************ //
export const DEFAULT_INVOICE_SEARCH_PARAMETER = getDefaultFilterSearchParam(
  getNoFilterOptionBySource('invoice')?.filterJson,
);
/**
 * An atom that stores the latest invoice search param
 */
export const latestInvoiceSearchParamAtom = atom<TLocationRecord>({
  key: 'latestInvoiceSearchParamAtom',
  default: { search: DEFAULT_INVOICE_SEARCH_PARAMETER, ready: false },
});

// *********************************************** //
//      Invoice List Search Result States     //
// *********************************************** //

/**
 * An atom that stores that stores the refresh Request ID for invoice list
 */
export const invoiceSearchRequestIDAtom = atom<number>({
  key: 'invoiceSearchRequestIDAtom',
  default: 0,
});

/**
 * An selector that stores the invoice detail list based on current searchParam
 */
export const invoiceCompleteListSelector = selector<ListState<IEpSubsInvoiceList>>({
  key: 'invoiceCompleteListSelector',
  get: async ({ get }) => {
    const idListState = get(
      basicFilterEnabledSearchListSelectorsFamily('invoice'),
    ) as ListState<IEpSubsCompositeDBInvoiceValueInList>;
    if (!idListState.err) {
      return {
        loading: false,
        err: NO_ERROR_IN_TABLE,
        data: (idListState.data.map(val => val.invoice) as any) ?? [],
        total: idListState.total,
      };
    } else {
      // if there is error, return same state from invoiceBasicListSelector
      return {
        loading: false,
        err: NO_ERROR_IN_TABLE,
        data: [],
        total: 0,
      };
    }
  },
});

// *********************************************** //
//        Invoice Detail Family States             //
// *********************************************** //
/**
 * An atomFamily that stores the refresh Request ID for each invoice
 */
export const invoiceRefreshRequestIDAtoms = atomFamily<number, number>({
  key: 'invoiceRefreshRequestIDAtoms',
  default: 0,
});

/**
 * An selectorFamily that stores invoice details for each invoice
 */
export const rawInvoiceSelectors = selectorFamily<IEpSubsCompositeDBInvoiceValue, number>(
  {
    key: 'rawInvoiceSelectors',
    get:
      invoiceId =>
      async ({ get }) => {
        get(invoiceRefreshRequestIDAtoms(invoiceId)); // Add request ID as a dependency
        const invoiceData = await genInvoiceData(invoiceId);
        return invoiceData;
      },
  },
);

/**
 * An selectorFamily that stores invoice details for each invoice
 */
export const invoiceSelectors = selectorFamily<IEpSubsCompositeDBInvoiceValue, number>({
  key: 'invoiceSelectors',
  get:
    invoiceId =>
    async ({ get }) => {
      get(invoiceRefreshRequestIDAtoms(invoiceId)); // Add request ID as a dependency
      let invoiceData = get(rawInvoiceSelectors(invoiceId));
      DEBUGGING && console.log('recoil, invoiceSelectors rawInvoiceData:', invoiceData);
      const customerId = invoiceData.invoice.customer_id;
      const customerDetailEnabled = get(customerDetailEnabledAtomFamily(customerId));
      if (customerDetailEnabled) {
        invoiceData = {
          ...invoiceData,
          customer: get(customerSelectors(customerId)).customer,
        };
        DEBUGGING && console.log('recoil, invoiceSelectors invoiceData:', invoiceData);
      }
      return invoiceData;
    },
});
