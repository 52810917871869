import {
  IEpSubsDBCustomer_addressValue,
  IEpSubsCustomer_payment_methodApiValue,
  INewAddressFormData,
} from 'src/models';

export const MAX_LENGTH_COMMENT = 1800;
export const MAX_LENGTH_NOTE = 1800;

export const strReplaceAll = (
  originalStr: string,
  matchedStrSegment: string,
  targetStrSegment: string,
): string => {
  if (originalStr !== undefined) {
    return originalStr.split(matchedStrSegment).join(targetStrSegment);
  } else {
    return '';
  }
};

export const strReplaceAllByPairs = (
  originalStr: string,
  replacePair: { match: string; target: string }[],
): string => {
  let replacedStr = originalStr;
  replacePair.map(pair => {
    replacedStr = replacedStr.split(pair.match).join(pair.target);
  });
  return replacedStr;
};

export function toCamelCase(str: string): string {
  return str
    .split(' ')
    .map(function (word, index) {
      // If it is the first word make sure to lowercase all the chars.
      if (index == 0) {
        return word.toLowerCase();
      }
      // If it is not the first word only upper case the first char and lowercase the rest.
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join('');
}

export function splitCamelCase(str: string): string {
  return str.replace(/([a-z])([A-Z])/, '$1 $2');
}

export function toInitialUpperCase(str: string, camelized = true): string {
  const dotRemovedStr = strReplaceAll(str, '.', ' - ');
  const underscoreRemovedStr = strReplaceAll(dotRemovedStr, '_', ' ');
  return underscoreRemovedStr
    .split(' ')
    .map(function (word) {
      return (
        word.charAt(0).toUpperCase() +
        (camelized ? word.slice(1).toLowerCase() : word.slice(1))
      );
    })
    .join(' ');
}

/**
 * Used to convert a dynamic string to a standard key to be used by cypress or cmd
 * @param str Original Dynamic string
 * @returns Replace '.' with '-', ' ' with '_', and lowercase all letters
 */
export function toStringKey(str: string): string {
  const dotRemovedStr = strReplaceAll(str, '.', '-');
  const slashRemovedStr = strReplaceAll(dotRemovedStr, '/', '');
  const underscoreAddedStr = strReplaceAll(slashRemovedStr, ' ', '_');
  return underscoreAddedStr.toLowerCase();
}

/**
 * A typical useCase is to extract the shortName of TimeZone
 * @example: 'Eastern Daylight Time' => 'EDT'
 */
export function extractInitialUpperCase(str: string): string {
  return str
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase();
    })
    .join('');
}

export function isNumeric(str: string | any): boolean {
  if (typeof str !== 'string') return false; // we only process strings!
  return (
    !Number.isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !Number.isNaN(Number.parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export function formatPostBody(content: string): string {
  if (!content) {
    return '';
  }
  content = content.replace(/<\/?[^>]+(>|$)/g, '');
  if (content.length > 50) {
    content = content.slice(0, 50) + ' ...';
  }
  if (content?.trim().length === 0) {
    content = '...';
  }
  return content;
}
export function isAlphaNumeric(str: string): boolean {
  for (let i = 0; i < str.length; i++) {
    const code = str.charCodeAt(i);
    if (!(isNumberCode(code) || isLowerCaseCode(code) || isUpperCaseCode(code))) {
      return false;
    }
  }
  return true;
}

export function isLowerCaseCode(code: number): boolean {
  return code > 96 && code < 123; // lower alpha (a-z)
}

export function trimOrFalse(val: string | undefined): string | false {
  if (!val) {
    return false;
  }
  val = val?.trim();
  return val.length > 0 ? val : false;
}

export function isUpperCaseCode(code: number): boolean {
  return code > 64 && code < 91; // upper alpha (A-Z)
}

export function isNumberCode(code: number): boolean {
  return code > 47 && code < 58;
}
export function hasNumber(str: string): boolean {
  for (let i = 0; i < str.length; i++) {
    const code = str.charCodeAt(i);
    if (isNumberCode(code)) {
      return true;
    }
  }
  return false;
}
export function hasUpperCase(str: string): boolean {
  for (let i = 0; i < str.length; i++) {
    const code = str.charCodeAt(i);
    if (isUpperCaseCode(code)) {
      return true;
    }
  }
  return false;
}

export function hasLowerCase(str: string): boolean {
  for (let i = 0; i < str.length; i++) {
    const code = str.charCodeAt(i);
    if (isLowerCaseCode(code)) {
      return true;
    }
  }
  return false;
}

function makeId(length: number) {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function generateUserName(nickName: string): string {
  let val = '';
  let hasFirst = false;
  for (let i = 0; i < nickName.length; i++) {
    const code = nickName.charCodeAt(i);
    if (!hasFirst) {
      if (isUpperCaseCode(code) || isLowerCaseCode(code)) {
        val += nickName[i].toLowerCase();
        hasFirst = true;
      }
    } else if (isUpperCaseCode(code) || isLowerCaseCode(code) || isNumberCode(code)) {
      val += nickName[i];
    }
  }
  if (val.length === 0) {
    val += makeId(4);
  }
  val += Math.floor(1000 + Math.random() * 9000);

  return val;
}

export const replaceURL = (val: string): string => {
  const reg =
    /(https?:\/\/(?:www\.|(?!www))[\da-z][\da-z-]+[\da-z]\.\S{2,}|www\.[\da-z][\da-z-]+[\da-z]\.\S{2,}|https?:\/\/(?:www\.|(?!www))[\da-z]+\.\S{2,}|www\.[\da-z]+\.\S{2,})/gi;
  const content =
    val?.replace(reg, `<a target="_blank" rel="noreferrer" href='$1'>$1</a>`) ?? '';
  return content;
};

export const countFormatter = (count: number): string => {
  if (!count) {
    return '0';
  }

  let formattedString = '';

  if (count >= 1000) {
    const suffixes = ['', 'K', 'M', 'B', 'T'];
    const suffixNum = Math.floor((('' + count).length - 1) / 3);
    let shortValue = 0;
    for (let precision = 3; precision >= 1; precision--) {
      shortValue = Number.parseFloat(
        (suffixNum !== 0 ? count / 1000 ** suffixNum : count).toPrecision(precision),
      );
      const dotLessShortValue = (shortValue + '').replace(/[^\d A-Za-z]+/g, '');
      if (dotLessShortValue.length <= 3) {
        break;
      }
    }
    if (shortValue % 1 !== 0) {
      formattedString = shortValue.toFixed(1) + suffixes[suffixNum];
    } else {
      formattedString = shortValue + suffixes[suffixNum];
    }
  } else {
    formattedString = count.toString();
  }

  return formattedString;
};

export const getAddressConcat = (
  address: IEpSubsDBCustomer_addressValue | INewAddressFormData,
) => {
  let formattedString = '';

  formattedString = address.address1;
  if (address.address2) {
    formattedString += ' ' + address.address2;
  }
  formattedString += ', ' + address.city + ', ' + address.state_code + ' ' + address.zip;

  return formattedString;
};

export const getPaymentConcat = (
  payment: IEpSubsCustomer_payment_methodApiValue,
  gatewayType: string,
) => {
  if (gatewayType == 'braintree' && payment.payment_type !== 'PayPalAccount') {
    return (
      'Braintree' +
      ' ' +
      `${payment.card_type}` +
      ' ' +
      'ending' +
      ' ' +
      payment.last4_digits +
      ', ' +
      payment.expiration_month +
      '/' +
      payment.expiration_year
    );
  }
  if (
    gatewayType == 'stripe' &&
    (payment.payment_type == 'CreditCard' ||
      payment.payment_type == 'DebitCard' ||
      payment.payment_type == 'PrepaidCard')
  ) {
    return (
      'Stripe' +
      ' ' +
      `${payment.card_type}` +
      ' ' +
      'ending' +
      ' ' +
      payment.last4_digits +
      ', ' +
      payment.expiration_month +
      '/' +
      payment.expiration_year
    );
  }
  if (gatewayType == 'paypal_express' && payment.payment_type == 'PayPalExpress') {
    return 'PayPal Express' + ' ' + `${payment.email}`;
  }
  if (gatewayType == 'braintree' && payment.payment_type == 'PayPalAccount') {
    return 'PayPal Account' + ' ' + `${payment.email}`;
  }
  if (gatewayType == 'stripe' && payment.payment_type == 'Sepa_Debit') {
    return 'Sepa Debit' + ' ' + 'ending' + ' ' + payment.last4_digits;
  }
  if (gatewayType == 'stripe' && payment.payment_type == 'Bacs_Debit') {
    return 'Bacs Debit' + ' ' + 'ending' + ' ' + payment.last4_digits;
  }
  if (gatewayType == 'stripe' && payment.payment_type == 'au_becs_debit') {
    return 'Becs Debit' + ' ' + 'ending' + ' ' + payment.last4_digits;
  }
  if (gatewayType == 'stripe' && payment.payment_type == 'boleto') {
    return 'Boleto';
  }
};
export const beautifyUnderscoreString = (input: string) => {
  const newInput = input.split('_').map(element => {
    return element.charAt(0).toUpperCase() + element.slice(1);
  });
  return newInput.join(' ');
};

export const generateUUID = () => {
  const array = new Uint8Array(16);
  window.crypto.getRandomValues(array);

  // Set the version number to 4
  array[6] = (array[6] & 0x0f) | 0x40;
  // Set the variant to RFC4122
  array[8] = (array[8] & 0x3f) | 0x80;

  const hexArray = Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
  return `${hexArray.substring(0, 8)}-${hexArray.substring(8, 12)}-${hexArray.substring(
    12,
    16,
  )}-${hexArray.substring(16, 20)}-${hexArray.substring(20, 32)}`;
};
