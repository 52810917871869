import { CENTS_IN_DOLLAR } from 'src/components/money';
import { LOCALE } from 'src/config';
import { ELocale } from '.';
import { TFlatDropdownItem } from 'src/components/dropdownSelect/ESGeneralDropdownInput';
import { notEmpty } from 'src/utilities/commonUtils';

export const getMoneySymbol = (
  locale = LOCALE as ELocale,
  moneyNumber?: string,
): string => {
  switch (locale) {
    case ELocale.ja_JP:
      return `¥${moneyNumber ?? ''}`;
    case ELocale.en_AU:
      return `A$${moneyNumber ?? ''}`;
    case ELocale.de_DE:
    case ELocale.fr_FR:
    case ELocale.sk_SK:
      return `${moneyNumber ?? ''}€`;
    case ELocale.en_UK:
      return `£${moneyNumber ?? ''}`;
    case ELocale.cs_CZ:
      return `${moneyNumber ?? ''}Kč`;
    case ELocale.pt_BR:
      return `R$${moneyNumber ?? ''}`;
    case ELocale.en_US:
    case ELocale.es_MX:
    case ELocale.es_US:
    default:
      return `$${moneyNumber ?? ''}`;
  }
};

export const getMoneyStorageConversionUnits = (locale: ELocale): number => {
  switch (locale) {
    case ELocale.ja_JP:
      return 1; // YEN_IN_YEN, BE store YEN directly
    case ELocale.en_US:
    case ELocale.en_AU:
    case ELocale.fr_FR:
    case ELocale.de_DE:
    case ELocale.es_MX:
    case ELocale.es_US:
    case ELocale.en_UK:
    case ELocale.cs_CZ:
    case ELocale.pt_BR:
    case ELocale.sk_SK:
    default:
      return CENTS_IN_DOLLAR;
  }
};

export const getMoneyDisplayPrecision = (locale = LOCALE as ELocale): number => {
  switch (locale) {
    case ELocale.ja_JP:
      // Display in JPYen without fractal digits
      return 0;
    case ELocale.en_US:
    case ELocale.en_AU:
    case ELocale.de_DE:
    case ELocale.fr_FR:
    case ELocale.es_MX:
    case ELocale.es_US:
    case ELocale.en_UK:
    case ELocale.cs_CZ:
    case ELocale.pt_BR:
    case ELocale.sk_SK:
    default:
      return 2;
  }
};

export const getMoneyDisplayPrecisionByCurrency = (currencyCode: string): number => {
  switch (currencyCode) {
    case 'JPY':
      // Display in JPYen without fractal digits
      return 0;
    case 'USD':
    case 'AUD':
    case 'EUR':
    case 'MXN':
    case 'GBP':
    case 'CZK':
    case 'BRL':
    case 'CHF':
    default:
      return 2;
  }
};

export const getMoneyStorageConversionUnitsByCurrencyCode = (
  currencyCode: string,
): number => {
  switch (currencyCode) {
    case 'JPY':
      return 1; // YEN_IN_YEN, BE store YEN directly
    case 'USD':
    case 'AUD':
    case 'EUR':
    case 'MXN':
    case 'GBP':
    case 'CZK':
    case 'BRL':
    case 'CHF':
    default:
      return CENTS_IN_DOLLAR;
  }
};

export const getMoneyDisplayPrecisionByCurrencyCode = (currencyCode: string): number => {
  switch (currencyCode) {
    case 'JPY':
      // Display in JPYen without fractal digits
      return 0;
    case 'USD':
    case 'AUD':
    case 'EUR':
    case 'MXN':
    case 'GBP':
    case 'CZK':
    case 'BRL':
    case 'CHF':
    default:
      return 2;
  }
};

export const getMoneyChangeUnitStr = (locale = LOCALE as ELocale): string => {
  //precision jp: 0, others: 2
  const precision = getMoneyDisplayPrecision(locale);
  return Math.pow(0.1, precision).toFixed(precision);
};

export const getMoneyChangeUnitStrByCurrencyCode = (currencyCode: string): string => {
  //precision jp: 0, others: 2
  const precision = getMoneyDisplayPrecisionByCurrency(currencyCode);
  return Math.pow(0.1, precision).toFixed(precision);
};

export const getCurrencyCode = (locale = LOCALE as ELocale): string => {
  switch (locale) {
    case ELocale.ja_JP:
      return 'JPY';
    case ELocale.en_AU:
      return 'AUD';
    case ELocale.de_DE:
    case ELocale.fr_FR:
    case ELocale.sk_SK:
      return 'EUR';
    case ELocale.en_UK:
      return 'GBP';
    case ELocale.cs_CZ:
      return 'CZK';
    case ELocale.es_MX:
      return 'MXN';
    case ELocale.pt_BR:
      return 'BRL';
    case ELocale.en_US:
    case ELocale.es_US:
      return 'USD';
    default:
      return 'USD';
  }
};

export const CURRENCY_OPTION: TFlatDropdownItem<string>[] = [
  { label: 'JPY', value: 'JPY' },
  { label: 'EUR', value: 'EUR' },
  { label: 'AUD', value: 'AUD' },
  { label: 'GBP', value: 'GBP' },
  { label: 'USD', value: 'USD' },
  { label: 'CZK', value: 'CZK' },
  { label: 'MXN', value: 'MXN' },
  { label: 'BRL', value: 'BRL' },
  { label: 'CHF', value: 'CHF' },
].filter(notEmpty);

export const convertMoneySignDependLocale = (
  moneyValue: number,
  locale: string = LOCALE,
): string => {
  const newMoneyValue = (
    moneyValue / getMoneyStorageConversionUnits(locale as ELocale)
  ).toLocaleString(`${LOCALE as ELocale}`, {
    style: 'currency',
    currency: `${getCurrencyCode(locale as ELocale)}`,
  });
  if (LOCALE == ELocale.en_AU) {
    return moneyValue < 0
      ? `-${convertMoneySignDependLocale(-moneyValue, locale)}`
      : `A${newMoneyValue}`;
  } else {
    return newMoneyValue;
  }
};

export const convertCurrencyWithCurrencyCode = (
  moneyValue: number,
  currencyCode: string,
  locale: string = LOCALE,
) => {
  const newCurrencyValue = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode ? currencyCode : getCurrencyCode(LOCALE as ELocale),
  }).format(moneyValue);
  if (currencyCode == 'CHF') {
    return newCurrencyValue.replace('CHF', '₣');
  } else {
    return newCurrencyValue;
  }
};
