import axios from 'axios';
import {
  IAllCountryRuleData,
  ICountryRuleData,
  ICountryStateData,
  IPureAddressData,
} from 'src/components/addresses/EpSubsAddressFormSubSection.js';
import { hostUrl } from '../config.js';
import {
  IEpSubsDBCustomer_addressValue,
  IEpSubsDBCustomer_addressPost,
  TEpSubsDBResponseOk,
  TEpSubsSubscriptionAddressUpdateData,
  TEpSubsAddressUsage,
  IEpSubsMelissaAddress,
} from '../models';

export const genAddressListForCustomer = async (
  customerId: number,
): Promise<TEpSubsDBResponseOk<IEpSubsDBCustomer_addressValue>> => {
  return axios.get(`${hostUrl}/customers/${customerId}/address`).then(response => {
    return response.data;
  });
};

export const genCreateCustomerAddress = async (
  customer_id: number,
  data: IEpSubsDBCustomer_addressPost,
): Promise<string> => {
  return axios
    .post(`${hostUrl}/customers/${customer_id}/address`, data)
    .then(response => {
      return response.data.data;
    });
};

export const genUpdateCustomerAddress = async (
  customerId: number,
  address_id: number,
  data: Omit<
    IEpSubsDBCustomer_addressValue,
    'id' | 'customer_id' | 'created_at' | 'created_by' | 'updated_by' | 'updated_at'
  >,
): Promise<string> => {
  return axios
    .put(`${hostUrl}/customers/${customerId}/address/${address_id}`, data)
    .then(response => {
      return response.data;
    });
};

export const genUpdateSubscriptionAddresses = async (
  address_id: number,
  data: TEpSubsSubscriptionAddressUpdateData,
): Promise<string> => {
  return axios
    .post(`${hostUrl}/subscriptions/update-address/${address_id}`, data)
    .then(response => {
      return response.data;
    });
};

export const genCustomerAddressUsage = async (
  address_id: number,
): Promise<TEpSubsAddressUsage[]> => {
  return axios.get(`${hostUrl}/customers/address-usage/${address_id}`).then(response => {
    return response.data;
  });
};

export const genDeleteCustomerAddress = async (
  customerId: number,
  id: number,
): Promise<boolean> => {
  return axios.delete(`${hostUrl}/customers/${customerId}/address/${id}`).then(() => {
    return true;
  });
};

export const genMelissaVerifyCustomerAddress = async (
  address1: string,
  suite: string | undefined,
  state_code: string,
  zip: string,
): Promise<IEpSubsMelissaAddress['address']> => {
  const data = {
    address1,
    suite,
    state_code,
    zip,
  };
  return axios.post(`${hostUrl}/address/verify`, data).then(response => {
    const fullData = response.data as IEpSubsMelissaAddress;
    if (fullData.address) {
      return fullData.address;
    } else {
      const message = fullData.results.map(item => item.message).join(' - ');
      throw Error(message);
    }
  });
};

export const genUSPSVerifyCustomerAddress = async ({
  address1,
  address2,
  zip,
}: {
  address1: string;
  address2?: string;
  zip: string;
}): Promise<IEpSubsDBCustomer_addressValue> => {
  return axios
    .get(
      `${hostUrl}/customers/usps-address?line1=${address1}&line2=${address2}&zip=${zip}`,
    )
    .then(response => {
      return response.data;
    });
};

export const genCheckDuplicatedAddress = async (
  data: Omit<IPureAddressData, 'country'>,
): Promise<{ type: 'dupe'; data: any[] } | { type: 'no_dupe'; message: string }> => {
  return axios
    .post(`${hostUrl}/subscriptions/validate-shipping-address`, data)
    .then(response => {
      if (!response.data.data) {
        return { ...response.data, type: 'no_dupe' };
      }
      return { ...response.data, type: 'dupe' };
    });
};

export const genCountryAddressRuleData = async (
  countryCode: string,
): Promise<ICountryRuleData> => {
  return axios.get(`${hostUrl}/customers/address-rule/${countryCode}`).then(response => {
    return response.data.address_rule;
  });
};

export const genCountryStateData = async (
  countryCode: string,
): Promise<ICountryStateData> => {
  return axios.get(`${hostUrl}/ref/${countryCode}/states`).then(response => {
    return response.data ? response.data : [];
  });
};

export const genAllCountryRuleData = async (): Promise<IAllCountryRuleData[]> => {
  return axios.get(`${hostUrl}/customers/country-rule`).then(response => {
    return response.data;
  });
};
