import { selector, selectorFamily, waitForAll } from 'recoil';
import * as H from 'history';
import {
  IEpSubsCompositeDBPlanValue,
  TEpSubsDBPlanValueKeys,
  IEpSubsDBPlan_activityValue,
  IEpSubsDBPlan_commentValue,
  UiPermission,
  IEpSubsDBFulfillmentCenterCompositeValue,
  ICurrency,
} from '../../models';
import {
  genPlanData,
  genPlanListData,
  genActivePlanListData,
  genPlanActivityData,
  genPlanActivityDetail,
  genPlanCommentData,
  genFulfillmentCentersData,
  genAllPlanListData,
  genPlanDataByStringId,
  genAllCurrencyListData,
} from '../../services/epSubsPlanService';
import {
  getParamsFromSearch,
  ListState,
  NO_ERROR_IN_TABLE,
} from '../../components/tables/EpFullFeaturedTable';
import { offsetToPageId } from '../../components/tables/EpFullFeaturedTable/Pagination/Pagination';
import { CURRENT_ID_ATOM_DEFAULT_VALUE, hasPermissionSelectors } from '..';
import { reduce } from 'lodash';
import { atom, atomFamily } from 'recoil';
import { TLocationRecord } from '../../components/tables/EpFullFeaturedTable';
import { DEFAULT_ACTIVITY_COUNT, DEFAULT_COMMENT_COUNT } from '../../js/constants/subs';
import { getSimpleFilterData } from 'src/components/navBars/EpSubsListNav/EpSubsListNavUtil';
import {
  DEFAULT_PLAN_SEARCH_OPTION,
  PLAN_STATUS_FILTER_GROUP_FIELD,
  PRODUCT_FILTER_GROUP_FIELD,
} from 'src/containers/plans/EpSubsPlanNav';
import { arrayToMap } from 'src/utilities/commonUtils';
import { DEBUGGING } from 'src/config';

// *********************************************** //
//             Plan List Page States               //
// *********************************************** //
export const DEFAULT_PLAN_SEARCH_PARAMETER = `?filter=${JSON.stringify(
  DEFAULT_PLAN_SEARCH_OPTION,
)}`;
// export const DEFAULT_PLAN_SEARCH_PARAMETER = `?order=&page=1&perPage=10&sort=&filter=${PLAN_STATUS.ACTIVE}&simpleQuery=`;
/**
 * An atom that stores the latest plan search param
 */
export const latestPlanSearchParamAtom = atom<TLocationRecord>({
  key: 'latestPlanSearchParamAtom',
  default: { search: DEFAULT_PLAN_SEARCH_PARAMETER, ready: false },
});

/**
 * An atom that stores that stores the refresh Request ID for plan list
 */
export const planSearchRequestIDAtom = atom<number>({
  key: 'planSearchRequestIDAtom',
  default: 0,
});

/**
 * An selector that stores the basic data for plan list based on current searchParam
 */
export const planBasicListSelector = selector<ListState<IEpSubsCompositeDBPlanValue>>({
  key: 'planIdListSelector',
  get: async ({ get }) => {
    const requestID = get(planSearchRequestIDAtom); // Add request ID as a dependency
    const planSearchParam = get(latestPlanSearchParamAtom);
    DEBUGGING &&
      console.log(
        'recoil, planBasicListSelector planSearchParam',
        planSearchParam,
        'requestID',
        requestID,
      );

    // fetch plans
    if (planSearchParam.ready && planSearchParam.search !== '') {
      const routeParams = getParamsFromSearch<TEpSubsDBPlanValueKeys>(
        planSearchParam.search as H.Search,
      );
      const { order, sort, page, perPage, simpleQuery } = routeParams;
      const filter = decodeURIComponent(routeParams.filter);
      const offset = (page - 1) * perPage;
      const filterData = getSimpleFilterData(filter);
      try {
        const response = await genPlanListData(
          perPage,
          offsetToPageId(offset, perPage),
          sort === ''
            ? [{ key: 'created_at', ascending: 'DESC' }]
            : [{ key: sort, ascending: order }],
          simpleQuery,
          filterData[PLAN_STATUS_FILTER_GROUP_FIELD][0],
          filterData[PRODUCT_FILTER_GROUP_FIELD][0],
        );

        DEBUGGING && console.log('recoil, planBasicListSelector response', response);

        return {
          loading: false,
          err: NO_ERROR_IN_TABLE,
          data: response.data ?? [],
          total: response.total ?? 0,
        };
      } catch (error) {
        console.log(`error`, error);
        return {
          loading: false,
          err: (error as Error).message,
          data: [],
          total: 0,
        };
      }
    }
    return {
      loading: false,
      err: NO_ERROR_IN_TABLE,
      data: [],
      total: 0,
    };
  },
});

/**
 * An selector that stores the plan detail list based on current searchParam
 */
export const planCompleteListSelector = selector<ListState<IEpSubsCompositeDBPlanValue>>({
  key: 'planCompleteListSelector',
  get: async ({ get }) => {
    const idListState = get(planBasicListSelector);

    if (!idListState.err) {
      const planIds = idListState.data.map(planData => planData.id);
      const planDetailList = get(
        waitForAll(planIds.map(planId => planSelectors(planId))),
      );
      DEBUGGING && console.log('recoil, planCompleteListSelector', planDetailList);

      return {
        loading: false,
        err: NO_ERROR_IN_TABLE,
        data: planDetailList ?? [],
        total: idListState.total,
      };
    } else {
      // if there is error, return same state from planBasicListSelector
      return idListState as ListState<IEpSubsCompositeDBPlanValue>;
    }
  },
});

/**
 * An atomFamily that stores the refresh Request ID for each plan
 */
export const planRefreshRequestIDAtoms = atomFamily<number, number>({
  key: 'planRefreshRequestIDAtoms',
  default: 0,
});

export const planStringRefreshRequestIDAtoms = atomFamily<string, string>({
  key: 'planStringRefreshRequestIDAtoms',
  default: '',
});

/**
 * An selectorFamily that stores plan details for each plan
 */
export const planSelectors = selectorFamily<IEpSubsCompositeDBPlanValue, number>({
  key: 'planSelectors',
  get:
    planId =>
    async ({ get }) => {
      get(planRefreshRequestIDAtoms(planId)); // Add request ID as a dependency
      const planData = await genPlanData(planId);
      DEBUGGING && console.log('recoil, planSelectors planData:', planData);
      return planData;
    },
});

export const planSelectorsByStringId = selectorFamily<
  IEpSubsCompositeDBPlanValue | undefined,
  string
>({
  key: 'planSelectorsByStringId',
  get:
    planId =>
    async ({ get }) => {
      if (planId !== '') {
        get(planStringRefreshRequestIDAtoms(planId));
        return await genPlanDataByStringId(planId);
      }
    },
});

// *********************************************** //
//           Plan Detail Page Selectors            //
// *********************************************** //

/**
 * An atom that stores the currently selected plan id
 */
export const currentPlanIdAtom = atom<number | null>({
  key: 'currentPlanIdAtom',
  default: CURRENT_ID_ATOM_DEFAULT_VALUE,
});

/**
 * An selector that stores the currently selected plan details
 */
export const currentPlanSelector = selector<IEpSubsCompositeDBPlanValue | undefined>({
  key: 'currentPlanSelector',
  get: ({ get }) => {
    const id = get(currentPlanIdAtom);

    if (id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return get(planSelectors(id));
    }
  },
});

/**
 * An atom that stores the currently selected plan Comments query parameters
 */
export const currentPlanCommentsSearchParamAtom = atom<{
  limit: number;
  requestId: number;
}>({
  key: 'currentPlanCommentsSearchParamAtom',
  default: {
    limit: DEFAULT_COMMENT_COUNT,
    requestId: 0, // for query refresh
  },
});

/**
 * An selector that stores the currently selected plan Comments
 */
export const currentPlanCommentsSelector = selector<IEpSubsDBPlan_commentValue[]>({
  key: 'currentPlanCommentsSelector',
  get: async ({ get }) => {
    const id = get(currentPlanIdAtom);
    const params = get(currentPlanCommentsSearchParamAtom);
    const hasPermission = get(hasPermissionSelectors(UiPermission['plan.view_comment']));
    if (hasPermission && id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return await genPlanCommentData(id, params.limit);
    } else {
      return [];
    }
  },
});

/**
 * An atom that stores the currently selected plan Activities query parameters
 */
export const currentPlanActivitiesSearchParamAtom = atom<{
  limit: number;
  requestId: number;
}>({
  key: 'currentPlanActivitiesSearchParamAtom',
  default: {
    limit: DEFAULT_ACTIVITY_COUNT,
    requestId: 0, // for query refresh
  },
});

/**
 * An selector that stores the currently selected plan Activities
 */
export const currentPlanActivitiesSelector = selector<IEpSubsDBPlan_activityValue[]>({
  key: 'currentPlanActivitiesSelector',
  get: async ({ get }) => {
    const id = get(currentPlanIdAtom);
    const params = get(currentPlanActivitiesSearchParamAtom);
    const hasPermission = get(hasPermissionSelectors(UiPermission['plan.view_log']));
    if (hasPermission && id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return await genPlanActivityData(id, params.limit);
    } else {
      return [];
    }
  },
});

/**
 * An selectorFamily that stores Plan Activity Detail for each activity
 */
export const planActivityDetailSelectors = selectorFamily<
  IEpSubsDBPlan_activityValue,
  number
>({
  key: 'planActivityDetailSelectors',
  get:
    actLogId =>
    async ({}) => {
      const planActivityData = await genPlanActivityDetail(actLogId);
      DEBUGGING &&
        console.log('recoil, planSelectors planActivityData:', planActivityData);
      return planActivityData;
    },
});

// *********************************************** //
//          Plan Dropdown Data Selectors           //
// *********************************************** //

/**
 * An atom that stores that stores the refresh Request ID for active plan list
 */
export const activePlansRequestIDAtom = atom<number>({
  key: 'activePlansRequestIDAtom',
  default: 0,
});

/**
 * An selector that stores the plan detail list
 */
export const activePlanListStateSelector = selector<
  ListState<IEpSubsCompositeDBPlanValue>
>({
  key: 'activePlanListStateSelector',
  get: async ({ get }) => {
    get(activePlansRequestIDAtom);
    const response = await genActivePlanListData();

    return {
      loading: false,
      err: NO_ERROR_IN_TABLE,
      data: response ?? [],
      total: response.length,
    };
  },
});

/**
 * An selector that stores the map from plan_id to plan_basic details
 */
export const activePlanDataMapSelector = selector<
  Map<string, IEpSubsCompositeDBPlanValue>
>({
  key: 'activePlanDataMapSelector',
  get: async ({ get }) => {
    const planList = get(activePlanListStateSelector).data ?? [];
    return arrayToMap(planList, plan => plan.plan_id);
  },
});

/**
 * An atom that stores that stores the refresh Request ID for active plan list
 */
export const allPlansRequestIDAtom = atom<number>({
  key: 'allPlansRequestIDAtom',
  default: 0,
});

/**
 * An selector that stores the plan detail list
 */
export const allPlanListDataSelector = selector<ListState<IEpSubsCompositeDBPlanValue>>({
  key: 'allPlanListDataSelector',
  get: async ({ get }) => {
    get(allPlansRequestIDAtom);
    const response = await genAllPlanListData();

    return {
      loading: false,
      err: NO_ERROR_IN_TABLE,
      data: response ?? [],
      total: response.length,
    };
  },
});

/**
 * An selector that stores the map from plan_id to plan_basic details
 */
export const allPlanDataMapSelector = selector<Map<string, IEpSubsCompositeDBPlanValue>>({
  key: 'allPlanDataMapSelector',
  get: async ({ get }) => {
    // To be replaced with a all plan list API
    const planList = get(allPlanListDataSelector).data ?? [];
    return arrayToMap(
      planList,
      plan => plan.plan_id,
      plan => plan,
    );
  },
});

// *********************************************** //
//  Fulfillment Center Dropdown Data Selectors     //
// *********************************************** //

/**
 * An atom that stores that stores the refresh Request ID for fullfillment center list
 */
export const fulfillmentCentersRequestIDAtom = atom<number>({
  key: 'fulfillmentCentersRequestIDAtom',
  default: 0,
});

// /**
//  * An atom that stores that stores the ID for fullfillment center
//  */
// export const fulfillmentCenterIDAtom = atom<number>({
//   key: 'fulfillmentCentersIDAtom',
//   default: 0,
// });
/**
 * An selector that stores the fulfillment centers detail list
 */
export const fulfillmentCentersListStateSelector = selector<
  ListState<IEpSubsDBFulfillmentCenterCompositeValue>
>({
  key: 'fulfillmentCentersListStateSelector',
  get: async ({ get }) => {
    get(fulfillmentCentersRequestIDAtom);
    const response = await genFulfillmentCentersData();

    DEBUGGING &&
      console.log('recoil, fulfillmentCentersListStateSelector response', response);

    return {
      loading: false,
      err: NO_ERROR_IN_TABLE,
      data: response ?? [],
      total: response.length,
    };
  },
});

/**
 * An selector that stores the map from fulfillmentCenter_id to fulfillmentCenter details
 */
export const allFulfillmentCentersDataMapSelector = selector<
  Map<string, IEpSubsDBFulfillmentCenterCompositeValue>
>({
  key: 'allFulfillmentCentersDataMapSelector',
  get: async ({ get }) => {
    // To be replaced with a all fulfillment center API
    const fulfillmentCenterList = get(fulfillmentCentersListStateSelector).data ?? [];
    return reduce<
      IEpSubsDBFulfillmentCenterCompositeValue,
      Map<string, IEpSubsDBFulfillmentCenterCompositeValue>
    >(
      fulfillmentCenterList,
      (fulfillmentCenterIdMap, fulfillmentCenterRecord) => {
        fulfillmentCenterIdMap.set(
          `${fulfillmentCenterRecord.id}`,
          fulfillmentCenterRecord,
        );
        return fulfillmentCenterIdMap;
      },
      new Map(),
    );
  },
});

/**
 * An selector that stores the currency list
 */
export const allCurrencyListSelector = selector<ICurrency[]>({
  key: 'allCurrencyListSelector',
  get: async ({}) => {
    const response = (await genAllCurrencyListData()) || [];
    const u: any = {};
    return response.filter(v => {
      return (u[v.code] = !u.hasOwnProperty(v.code));
    });
  },
});

// /**
//  * An selector that stores the single fulfillment center detail
//  */

// export const fulfillmentCentersStateSelector = selector<
//   IEpSubsDBFulfillment_centerValue | undefined
// >({
//   key: 'fulfillmentCentersStateSelector',
//   get: async ({ get }) => {
//     const id = get(fulfillmentCenterIDAtom);
//     if (id !== 0) {
//       const response = await genSingleFulfillmentCenterData(id);
//       return response;
//     }
//   },
// });
