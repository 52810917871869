import React, { useRef } from 'react';
import { Row } from 'react-bootstrap';
import ReactSelect, {
  StylesConfig,
  createFilter,
  components,
  ValueContainerProps,
} from 'react-select';
import { FilterGroupNameFlag } from 'src/components/flags/EpSubsFlags';
import style from './EpSubsSavedFilterSelector.module.scss';
import { DEBUGGING } from 'src/config';

export interface IMultipleSelectProps {
  onSelect: (option: TFilterSelectOption | null) => void;
  selectedOption: TFilterSelectOption;
  savedFilterOptions: TFilterSelectOption[] | null;
}

export type TFilterSelectOption = {
  label: string;
  value: string;
  filterJson?: string;
  groupName?: string;
  accessLevel: number;
  created_by?: number;
};

type IsMulti = false;
type TValueContainerChildren = { props: { data: TFilterSelectOption } }[];

export const EpSubsSavedFilterSelector = ({
  onSelect,
  selectedOption,
  savedFilterOptions,
}: IMultipleSelectProps): JSX.Element => {
  const selectRef = useRef<any>(null);

  const customStyles: StylesConfig<TFilterSelectOption, IsMulti> = {
    option: provided => ({
      ...provided,
      wordWrap: 'break-word',
    }),
  };

  // Used to customize the layout of selected options
  const ValueContainer = ({
    children,
    ...props
  }: ValueContainerProps<TFilterSelectOption, IsMulti>) => (
    <components.ValueContainer {...props}>
      {/* {console.log('ValueContainer: ', children)} */}
      <Row className={`ml-1 ${style.select_value}`}>
        {FilterGroupNameFlag(
          !!children && !!(children as TValueContainerChildren)[0]?.props?.data
            ? (children as TValueContainerChildren)[0].props.data.groupName
            : null,
          { margin: '0.3rem 0rem' },
        )}
        <div className="ml-1 font-weight-bold">{children}</div>
      </Row>
    </components.ValueContainer>
  );

  if (!savedFilterOptions || savedFilterOptions.length === 0)
    return <ReactSelect options={[]} />;

  const handleChange = (option: TFilterSelectOption | null) => {
    onSelect(option);
    DEBUGGING && console.log('Filter Selected Options: ', option);
  };

  return (
    <ReactSelect
      isMulti={false}
      isClearable={false}
      options={savedFilterOptions}
      // for typeahead filter, only search 'label' field, don't search 'value' field
      filterOption={createFilter({
        matchFrom: 'any',
        stringify: option => `${option.label}`,
      })}
      value={
        selectedOption
        // savedFilterOptions.find(obj => {
        //   return obj.value === selectedOption.value;
        // }) ?? selectedOption
      }
      // Used to avoid redundant option highlight
      // Ref: https://github.com/JedWatson/react-select/issues/1825
      getOptionValue={option => option.label + option.value}
      onChange={option => handleChange(option)}
      isDisabled={false}
      ref={ref => (selectRef.current = ref)}
      components={{ ValueContainer }}
      styles={customStyles}
      // onBlur={() => {
      //   renderProps.onBlur();
      //   // if (onBlur) onBlur();
      // }}
    />
  );
};
