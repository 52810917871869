import axios from 'axios';
import { hostUrl } from '../config.js';
import {
  IEpSubsDBAddonPost,
  IEpSubsDBAddonValue,
  TEpSubsDBAddonValueKeys,
  TEpSubsDBResponseOk,
  IEpSubsDBAddon_activityValue,
  IEpSubsDBAddon_commentValue,
} from '../models';
import {
  EMPTY_EXPORT_ERROR,
  getSortSQLStrFromSortingItems,
  TSortingItem,
} from './epSubsServiceUtils';
import FileDownload from 'js-file-download';
import { AddAddonFormData } from 'src/components/addon/EpSubsAddAddonInDetailPage/EpSubsAddAddonInDetailPage.js';
import { unixtimeWithoutTime } from 'src/components/formComponents/ESFormDateInput';
import { IEpSubsActiveAddonsValue } from 'src/recoil/index.js';
import { putNoty } from 'src/utilities/epSubsNoty';

export const genAddonListData = async (
  perPage = 10,
  pageIdx = 1,
  sortingItems: Array<TSortingItem<TEpSubsDBAddonValueKeys>> = [],
  simpleQuery = '',
  filter = '',
): Promise<TEpSubsDBResponseOk<IEpSubsDBAddonValue>> => {
  const sortSQLStr = getSortSQLStrFromSortingItems<TEpSubsDBAddonValueKeys>(sortingItems);
  return axios
    .get(
      `${hostUrl}/addons/search?` +
        [
          `key=${simpleQuery}`,
          `sort=${sortSQLStr}`,
          `per_page=${perPage}`,
          `page=${pageIdx - 1}`,
          `status=${filter}`,
        ].join('&'),
    )
    .then(response => {
      return response.data;
    });
};

export const genActiveAddonListData = async (): Promise<IEpSubsDBAddonValue[]> => {
  return axios.get(`${hostUrl}/active-addons`).then(response => {
    return response.data;
  });
};

export const genExportAddonList = async (
  sortingItems: Array<TSortingItem<TEpSubsDBAddonValueKeys>> = [],
  simpleQuery = '',
  filter = '',
): Promise<string> => {
  const sortSQLStr = getSortSQLStrFromSortingItems<TEpSubsDBAddonValueKeys>(sortingItems);
  return axios
    .get(
      `${hostUrl}/addons/export?` +
        [`key=${simpleQuery}`, `sort=${sortSQLStr}`, `status=${filter}`].join('&'),
    )
    .then(response => {
      const payloadFileStr = response.data;
      if (Object.keys(payloadFileStr).length === 0) throw new Error(EMPTY_EXPORT_ERROR);
      const fileName = `${filter.toUpperCase()}-ADDONS-${unixtimeWithoutTime(
        Date.now(),
      )}.csv`;
      FileDownload(payloadFileStr, fileName);
      return fileName;
    });
};

export const genAddonData = async (id: number): Promise<IEpSubsDBAddonValue> => {
  return axios.get(`${hostUrl}/addons/${id}`).then(response => {
    return response.data;
  });
};

export const genAddonActivityData = async (
  id: number,
  limit: number,
): Promise<IEpSubsDBAddon_activityValue[]> => {
  return axios.get(`${hostUrl}/addons/${id}/actlogs?limit=${limit}`).then(response => {
    return response.data.data;
  });
};

export const genAddonActivityDetail = async (
  actLogId: number,
): Promise<IEpSubsDBAddon_activityValue> => {
  return axios.get(`${hostUrl}/addons/actlogs/${actLogId}`).then(response => {
    return response.data;
  });
};

export const genAddonCommentData = async (
  id: number,
  limit?: number,
): Promise<IEpSubsDBAddon_commentValue[]> => {
  return axios
    .get(`${hostUrl}/addons/${id}/comments?${limit ? 'limit=' + limit : ''}`)
    .then(response => {
      return response.data.data;
    });
};

export const genCreateAddonComment = async (
  id: number,
  data: FormData,
  removeAllUploadedImage: () => void,
): Promise<string> => {
  return axios.post(`${hostUrl}/addons/${id}/comments`, data).then(response => {
    removeAllUploadedImage();
    return response.data;
  });
};

// export const genSampleAddonData = async (id: string): Promise<IEpSubsDBAddonValue> => {
//   const addonData = sampleData.data.filter(record => String(record.id) === id)[0];
//   return addonData;
// };

export const genCreateAddon = async (data: IEpSubsDBAddonPost): Promise<string> => {
  return axios.post(`${hostUrl}/addons`, data).then(response => {
    return response.data.data;
  });
};

export const genCloneAddon = async (id: number): Promise<string> => {
  return axios.post(`${hostUrl}/addons/${id}/clone`).then(response => {
    return response.data.data; // newAddonID
  });
};

export const genEditAddon = async (
  id: number,
  data: IEpSubsDBAddonPost,
): Promise<boolean> => {
  return axios.put(`${hostUrl}/addons/${id}`, data).then(() => {
    return true;
  });
};

export const genArchiveAddon = async (id: number, archive = true): Promise<boolean> => {
  const archiveParaStr = !archive ? 'archive=false' : '';
  return axios.post(`${hostUrl}/addons/${id}/archive?${archiveParaStr}`).then(() => {
    return true;
  });
};

export const genAddAddonInDetailPage = async (
  data: AddAddonFormData,
): Promise<
  number | { url?: string; returnUrl?: string; voucher: string; expire_at: string }
> => {
  return axios.post(`${hostUrl}/invoices/charge`, data).then(response => {
    if (response.data.code != undefined) {
      const message = `${response.data.message}`;
      putNoty({ type: 'error', text: message });
      const { url, return_url, voucher, expire_at } = JSON.parse(response.data.data);
      return { url, returnUrl: return_url, voucher: voucher, expire_at: expire_at };
    } else {
      const addOnResponseData = response.data.data;
      return addOnResponseData;
    }
  });
};

export const genRecurringAddonListData = async (
  isRecurring: boolean | null,
): Promise<IEpSubsDBAddonValue[]> => {
  return axios
    .get(`${hostUrl}/addons/search?key=${isRecurring ? 'recurring' : 'non_recurring'}`)
    .then(response => {
      return response.data.data;
    });
};

export const genAllAddonData = async (): Promise<IEpSubsActiveAddonsValue[]> => {
  return axios.get(`${hostUrl}/active-addons`).then(response => {
    return response.data;
  });
};
