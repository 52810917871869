import axios from 'axios';
import { hostUrl } from '../config.js';
import {
  IEpSubsDBPlanPost,
  IEpSubsCompositeDBPlanValue,
  TEpSubsDBPlanValueKeys,
  TEpSubsDBResponseOk,
  IEpSubsDBPlan_activityValue,
  IEpSubsDBPlan_commentValue,
  IEpSubsDBAddonValue,
  IEpSubsDBFulfillmentCenterCompositeValue,
  ICurrency,
} from '../models';
import {
  EMPTY_EXPORT_ERROR,
  getSortSQLStrFromSortingItems,
  TSortingItem,
} from './epSubsServiceUtils';
import FileDownload from 'js-file-download';
import { unixtimeWithoutTime } from 'src/components/formComponents/ESFormDateInput';

export const genPlanListData = async (
  perPage = 10,
  pageIdx = 1,
  sortingItems: Array<TSortingItem<TEpSubsDBPlanValueKeys>> = [],
  simpleQuery = '',
  status = '',
  productId = '',
): Promise<TEpSubsDBResponseOk<IEpSubsCompositeDBPlanValue>> => {
  const sortSQLStr = getSortSQLStrFromSortingItems<TEpSubsDBPlanValueKeys>(sortingItems);
  return axios
    .get(
      `${hostUrl}/plans/search?` +
        [
          `key=${simpleQuery}`,
          `sort=${sortSQLStr}`,
          `per_page=${perPage}`,
          `page=${pageIdx - 1}`,
          `status=${status}`,
          `product_id=${productId}`,
        ].join('&'),
    )
    .then(response => {
      return response.data;
    });
};

export const genActivePlanListData = async (): Promise<IEpSubsCompositeDBPlanValue[]> => {
  return axios.get(`${hostUrl}/active-plans`).then(response => {
    return response.data;
  });
};

export const genAllPlanListData = async (): Promise<IEpSubsCompositeDBPlanValue[]> => {
  return axios.get(`${hostUrl}/plans`).then(response => {
    return response.data.data;
  });
};

export const genExportPlanList = async (
  sortingItems: Array<TSortingItem<TEpSubsDBPlanValueKeys>> = [],
  simpleQuery = '',
  status = '',
  productId = '',
): Promise<string> => {
  const sortSQLStr = getSortSQLStrFromSortingItems<TEpSubsDBPlanValueKeys>(sortingItems);
  return axios
    .get(
      `${hostUrl}/plans/export?` +
        [
          `key=${simpleQuery}`,
          `sort=${sortSQLStr}`,
          `status=${status}`,
          `product_id=${productId}`,
        ].join('&'),
    )
    .then(response => {
      const payloadFileStr = response.data;
      if (Object.keys(payloadFileStr).length === 0) throw new Error(EMPTY_EXPORT_ERROR);
      const fileName =
        `${status.toUpperCase()}-PLANS-` + `${unixtimeWithoutTime(Date.now())}.csv`;
      FileDownload(payloadFileStr, fileName);
      return fileName;
    });
};

export const genPlanData = async (id: number): Promise<IEpSubsCompositeDBPlanValue> => {
  return axios.get(`${hostUrl}/plans/${id}`).then(response => {
    return response.data;
  });
};

export const genPlanDataByStringId = async (
  id: string,
): Promise<IEpSubsCompositeDBPlanValue> => {
  return axios.get(`${hostUrl}/plans/plan-id/${id}`).then(response => {
    return response.data;
  });
};

export const genPlanActivityData = async (
  id: number,
  limit: number,
): Promise<IEpSubsDBPlan_activityValue[]> => {
  return axios.get(`${hostUrl}/plans/${id}/actlogs?limit=${limit}`).then(response => {
    return response.data.data;
  });
};

export const genPlanActivityDetail = async (
  actLogId: number,
): Promise<IEpSubsDBPlan_activityValue> => {
  return axios.get(`${hostUrl}/plans/actlogs/${actLogId}`).then(response => {
    return response.data;
  });
};

export const genPlanCommentData = async (
  id: number,
  limit?: number,
): Promise<IEpSubsDBPlan_commentValue[]> => {
  return axios
    .get(`${hostUrl}/plans/${id}/comments?${limit ? 'limit=' + limit : ''}`)
    .then(response => {
      return response.data.data;
    });
};

export const genCreatePlanComment = async (
  id: number,
  data: FormData,
  removeAllUploadedImage: () => void,
): Promise<string> => {
  return axios.post(`${hostUrl}/plans/${id}/comments`, data).then(response => {
    removeAllUploadedImage();
    return response.data;
  });
};

// export const genSamplePlanData = async (id: string): Promise<IEpSubsCompositeDBPlanValue> => {
//   const planData = sampleData.data.filter(record => String(record.id) === id)[0];
//   return planData;
// };

export const genCreatePlan = async (data: IEpSubsDBPlanPost): Promise<string> => {
  return axios.post(`${hostUrl}/plans`, data).then(response => {
    return response.data.data;
  });
};

export const genClonePlan = async (id: number): Promise<string> => {
  return axios.post(`${hostUrl}/plans/${id}/clone`).then(response => {
    return response.data.data; // newPlanID
  });
};

export const genEditPlan = async (
  id: number,
  data: IEpSubsDBPlanPost,
): Promise<boolean> => {
  return axios.put(`${hostUrl}/plans/${id}`, data).then(() => {
    return true;
  });
};

export const genArchivePlan = async (id: number, archive = true): Promise<boolean> => {
  const archiveParaStr = !archive ? 'archive=false' : '';
  return axios.post(`${hostUrl}/plans/${id}/archive?${archiveParaStr}`).then(() => {
    return true;
  });
};

/**
 * @param id Both internal integer plan.id and plan_id string are working here.
 */
export const genMatchedAddonByPlan = async (
  id: string | number,
): Promise<IEpSubsDBAddonValue[]> => {
  return axios.get(`${hostUrl}/plans/${id}/addon`).then(response => {
    return response.data;
  });
};

export const genFulfillmentCentersData = async (): Promise<
  IEpSubsDBFulfillmentCenterCompositeValue[]
> => {
  return axios.get(`${hostUrl}/fulfillment-centers`).then(response => {
    return response.data.data ? response.data.data : [];
  });
};

export const genSingleFulfillmentCenterData = async (
  id: number,
): Promise<IEpSubsDBFulfillmentCenterCompositeValue | undefined> => {
  return axios.get(`${hostUrl}/fulfillment-centers/${id}`).then(response => {
    return response.data;
  });
};

export const genAllCurrencyListData = async (): Promise<ICurrency[]> => {
  return axios.get(`${hostUrl}/ref/currency`).then(response => {
    return response.data;
  });
};
