/* eslint-disable react/react-in-jsx-scope */
import activityLogBtn from './activityLogBtn.png';
import address from './address.png';
import customer from './customer.png';
import email from './email.png';
import exportBtn from './exportBtn.png';
import leftArrow from './leftArrow.png';
import optBtn from './optBtn.png';
import payment from './payment.png';
import phone from './phone.png';
import rightArrow from './rightArrow.png';
import sort from './sort.png';
import sortGrey from './sortGrey.png';
import timeChange from './timeChange.png';
import infoIcon from './infoIcon.png';
import warning from './warning.png';
import primary from './PrimaryMethod.png';
import homeBarInfo from './homeBarInfo.png';
import thickDownArrow from './thickDownArrow.png';
import thickUpArrow from './thickUpArrow.png';
import search from './search.png';
import filter from './filter.png';
import edit from './edit.png';
import trashBin from './trashBin.png';
import magnifyingGlass from './magnifyingGlass.png';
import check from './check.png';
import error from './error.png';
import id from './id.png';
import rename from './rename.png';
import saveAsNew from './saveAsNew.png';
import saveAs from './saveAs.png';
import save from './save.png';
import epochTimesLogo from './epochTimesLogo.png';
import amex from './creditCardAmex.png';
import discover from './creditCardDiscover.png';
import master from './creditCardMaster.png';
import visa from './creditCardVisa.png';
import creditCard from './creditCard.png';
import prepaidCard from './prepaidCard.png';
import paypal from './paypal.png';
import settings from './settings.png';
import rubber from './rubber.png';
import key from './key.png';
import subscription from './subscription.png';
import conductor from './conductor.png';
import paymentWarning from './warningPayment.png';
import download from './download.png';
import sepaDebit from './sepa.png';
import paypalButton from './payPalButton.png';
import stripe from './stripe.png';
import becs from './becs.png';
import bacs from './bacs.png';
import logOut from './logoutIcon.png';
import home from './home.png';
import user from './user.png';
import subscribe from './subscribe.png';
import invoice from './invoice.png';
import product from './product.png';
import logs from './logs.png';
import centers from './fulfillmentCenter.png';
import setting from './setting.png';
import operations from './operations.png';
import plusIcon from './plusIcon.png';
import calendar from './calendarIcon.png';
import boleto from './boleto.png';

export const IconFactory = {
  activityLogBtn,
  address,
  customer,
  email,
  exportBtn,
  leftArrow,
  optBtn,
  payment,
  phone,
  rightArrow,
  sort,
  sortGrey,
  timeChange,
  infoIcon,
  warning,
  primary,
  homeBarInfo,
  thickDownArrow,
  thickUpArrow,
  search,
  filter,
  edit,
  magnifyingGlass,
  trashBin,
  check,
  error,
  id,
  rename,
  saveAsNew,
  saveAs,
  save,
  epochTimesLogo,
  visa,
  amex,
  discover,
  master,
  creditCard,
  prepaidCard,
  paypal,
  settings,
  rubber,
  key,
  subscription,
  conductor,
  paymentWarning,
  download,
  sepaDebit,
  paypalButton,
  stripe,
  becs,
  bacs,
  logOut,
  home,
  user,
  subscribe,
  invoice,
  product,
  logs,
  centers,
  setting,
  operations,
  plusIcon,
  calendar,
  boleto,
};

const defaultStyle = { height: '20px', marginRight: '10px', marginLeft: '10px' };
/**
 * @param tabIndex Needed if we need the icon focusable.
 * @param style Default style is { height: '20px', marginRight: '10px', marginLeft: '10px' },
 * input style will overwrite them by attributes.
 */
export const EpIcon = ({
  name,
  className,
  style,
  tabIndex,
  onClick,
  role,
}: {
  name: keyof typeof IconFactory;
  className?: string;
  style?: {
    [key in
      | 'height'
      | 'marginRight'
      | 'marginLeft'
      | 'marginTop'
      | 'verticalAlign'
      | 'margin']?: string;
  };
  tabIndex?: number;
  onClick?: () => void;
  role?: string;
}): JSX.Element => {
  return (
    <img
      src={IconFactory[name]}
      className={`${className}`}
      role={role}
      style={{ ...defaultStyle, ...style }}
      tabIndex={tabIndex}
      alt=""
      onClick={onClick}
    />
  );
};
