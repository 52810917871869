import { atom, selector, selectorFamily } from 'recoil';
import jwt_decode from 'jwt-decode';
import {
  getAllPendingUsers,
  getAllActiveUsers,
  genUserListData,
  getProfile,
  getAllUsersApiMapping,
} from 'src/services/epSubsUserService';
import {
  IEpSubsProfile,
  IEpSubsUserRoles,
  IEpSubsUserRolesKeys,
  IEpSubsUsersApiMapping,
  Permission,
} from 'src/models';
import axios from 'axios';
import { AUTH_TOKEN } from '../../js/constants/subs';
import {
  getParamsFromSearch,
  ListState,
  NO_ERROR_IN_TABLE,
  TLocationRecord,
} from '../../components/tables/EpFullFeaturedTable';
import * as H from 'history';
import { offsetToPageId } from '../../components/tables/EpFullFeaturedTable/Pagination/Pagination';
import { transactionSearchRequestIDAtom } from '../transaction/transactionListPageStates';
import { getSimpleFilterData } from '../../components/navBars/EpSubsListNav/EpSubsListNavUtil';
import { USER_STATUS_FILTER_GROUP_FIELD } from '../../containers/users/usersList/EpSubsUsersListNav';
import { arrayToMap } from 'src/utilities/commonUtils';

// *********************************************** //
//              Login User States                  //
// *********************************************** //
/**
 * An atom that stores the logged in user JWT token:
 * https://jwt.io/introduction/
 * https://www.jsonwebtoken.io/
 */
export const jwtTokenAtom = atom<string>({
  key: 'jwtTokenAtom',
  default: selector({
    key: 'jwtTokenAtom/default',
    get: () => {
      // get default token from localStorage
      const jwtToken = localStorage.getItem(AUTH_TOKEN);
      if (jwtToken) {
        // Saves a bearer token inside Axios default header Authorization:
        axios.defaults.headers.Authorization = `Bearer ${jwtToken}`;

        return jwtToken;
      } else {
        return '';
      }
    },
  }),
});

export const userInfoRequestAtom = atom<number>({
  key: 'userInfoRequestAtom',
  default: 0,
});

export const userAPIMappingRequestAtom = atom<number>({
  key: 'userAPIMappingRequestAtom',
  default: 0,
});

export interface IEpSubsUser {
  email: string | null;
  permissions: Permission[];
  token: string;
}

/**
 * An selector that stores the logged in user info
 */
export const userSelector = selector<IEpSubsUser>({
  key: 'userSelector',
  get: ({ get }) => {
    const jwtToken = get(jwtTokenAtom);

    if (jwtToken) {
      const decoded: { email: string; permissions: string } = jwt_decode(jwtToken);
      // convert string to array: "view_plan,edit_plan"
      const permissions = decoded.permissions.split(',') as Permission[];

      return {
        email: decoded.email,
        permissions: permissions,
        token: jwtToken,
      };
    } else {
      return {
        email: null,
        permissions: [],
        token: '',
      };
    }
  },
});

/**
 * An selectorFamily that stores if the logged in user has specified permission
 */
export const hasPermissionSelectors = selectorFamily<boolean, Permission[]>({
  key: 'hasPermissionSelectors',
  get:
    allowedPermissions =>
    ({ get }) => {
      const userPermissions = get(userSelector).permissions;

      for (const permission of allowedPermissions) {
        if (userPermissions.includes(permission)) {
          return true;
        }
      }

      return false;
    },
});

// *********************************************** //
//              Active Users Lists States          //
// *********************************************** //

export const activeUsersListRequestIDAtom = atom<number>({
  key: 'activeUsersListRequestIDAtom',
  default: 0,
});

export const fetchNumberOfActiveUsersAtom = atom<number>({
  key: 'fetchNumberOfActiveUsersAtom',
  default: 30,
});

export const activeUsersListSelector = selector<IEpSubsUserRoles[]>({
  key: 'activeUsersListSelector',
  get: async ({ get }) => {
    get(activeUsersListRequestIDAtom);
    return await getAllActiveUsers();
  },
});

// *********************************************** //
//            Inactive Users Lists States          //
// *********************************************** //

export const pendingUsersListSelectorAtom = atom<number>({
  key: 'pendingUsersListSelectorAtom',
  default: 0,
});

export const pendingUsersListSelector = selector<IEpSubsUserRoles[]>({
  key: 'pendingUsersListSelector',
  get: async ({ get }) => {
    get(pendingUsersListSelectorAtom);
    return await getAllPendingUsers();
  },
});

// *********************************************** //
//      User Roles Selected States                //
// *********************************************** //

export const userRolesSelectedAtomUserId = atom<number>({
  key: 'userRolesSelectedAtomUserId',
  default: 0,
});

export const userRolesSelectedRolesAtom = atom<string[]>({
  key: 'userRolesSelectedRolesAtom',
  default: [],
});

export const DEFAULT_USERS_SEARCH_PARAMETER = '?filter={"status":["active"]}';
export const latestUserSearchParamAtom = atom<TLocationRecord>({
  key: 'latestUsersSearchParamAtom',
  default: { search: DEFAULT_USERS_SEARCH_PARAMETER, ready: false },
});

export const usersListSelector = selector<ListState<IEpSubsUserRoles>>({
  key: 'usersListSelector',
  get: async ({ get }) => {
    get(transactionSearchRequestIDAtom); // Add request ID as a dependency
    const usersSearchParam = get(latestUserSearchParamAtom);

    // fetch transactions
    if (usersSearchParam.ready && usersSearchParam.search !== '') {
      const routeParams = getParamsFromSearch<IEpSubsUserRolesKeys>(
        usersSearchParam.search as H.Search,
      );
      const { order, sort, page, perPage, simpleQuery } = routeParams;
      const filter = decodeURIComponent(routeParams.filter);
      const offset = (page - 1) * perPage;
      const filterData = getSimpleFilterData(filter);

      try {
        const response = await genUserListData(
          perPage,
          offsetToPageId(offset, perPage),
          sort === ''
            ? [{ key: 'id', ascending: 'ASC' }]
            : [{ key: sort, ascending: order }],
          simpleQuery,
          filterData[USER_STATUS_FILTER_GROUP_FIELD][0],
        );

        return {
          loading: false,
          err: NO_ERROR_IN_TABLE,
          data: response.data ?? [],
          total: response.total ?? 0,
        };
      } catch (error) {
        console.log(`error`, error);
        return {
          loading: false,
          err: (error as Error).message,
          data: [],
          total: 0,
        };
      }
    }
    return {
      loading: false,
      err: NO_ERROR_IN_TABLE,
      data: [],
      total: 0,
    };
  },
});

export const userInfoSelector = selector<IEpSubsProfile>({
  key: 'userInfoSelector',
  get: async ({ get }) => {
    get(userInfoRequestAtom);
    const userInfo = await getProfile();
    return userInfo;
  },
});

export const usersAPIMappingSelector = selector<IEpSubsUsersApiMapping[]>({
  key: 'usersAPIMappingSelector',
  get: async ({ get }) => {
    get(userAPIMappingRequestAtom);
    const userAPIMapping = await getAllUsersApiMapping();
    return userAPIMapping;
  },
});

export const usersApiMappingMapSelector = selector<Map<number, IEpSubsUsersApiMapping>>({
  key: 'usersApiMappingMapSelector',
  get: async ({ get }) => {
    const usersApisList = get(usersAPIMappingSelector);
    return arrayToMap(usersApisList, val => val.id);
  },
});
