import { ELocale } from 'src/models';
import { LOCALE } from 'src/config';
import {
  getMoneyDisplayPrecision,
  getMoneyDisplayPrecisionByCurrencyCode,
  getMoneyStorageConversionUnits,
  getMoneyStorageConversionUnitsByCurrencyCode,
} from 'src/models/i18n';

export const CENTS_IN_DOLLAR = 100;

/**
 * Convert the DB stored money value from BE in the unit of cents to human friendly version
 * complying with accounting convention for FE display.
 * The value of [null, undefined, ''] are all properly handled here.
 * @param moneyInStorage The input of money stored in DB from BE, in the unit - cents
 * @param addUnit Default true. Will add $ before the value string if true
 * ZeroDecimal?: boolean, // this props might be needed to consider as japanese currency case needs a zero decimal
 */

// *********************************************** //
//         Utilities For Money Format              //
// *********************************************** //
/**
 * For USD: Cents To Dollar with precision as a string, (value / conversionUnits).toFixed(precision)
 */
export const convertMoneyFromStorageToUiStr = (
  moneyInStorage: string | number,
  locale = LOCALE as ELocale,
): string => {
  const conversionUnits = getMoneyStorageConversionUnits(locale);
  const moneyValue = parseFloat(String(moneyInStorage)) / conversionUnits;
  const precision = getMoneyDisplayPrecision(locale);
  return getFloorAtGivenPrecision(moneyValue, precision).toFixed(precision);
};

export const convertMoneyFromStorageToUiStrByCurrencyCode = (
  moneyInStorage: number,
  currencyCode: string,
): number => {
  //JP = 1; others = 100;
  const conversionUnits = getMoneyStorageConversionUnitsByCurrencyCode(currencyCode);
  const moneyValue = parseFloat(String(moneyInStorage)) / conversionUnits;
  //JP = 0, others = 2;
  const precision = getMoneyDisplayPrecisionByCurrencyCode(currencyCode);
  return Number(getFloorAtGivenPrecision(moneyValue, precision).toFixed(precision));
};

/**
 * @param precision: non-negative integer. Return original num if less than 0.
 * @returns Instead of get floor to integer value, get floor to a certain fractal precision
 * @example
 * getFloorAtGivenPrecision(0.45, 1) -> 0.4
 * getFloorAtGivenPrecision(-0.45, 1) -> -0.4
 */
const getFloorAtGivenPrecision = (num: number, precision: number): number => {
  if (precision < 0) return num;

  const numStrWithExtraPrecision = num.toFixed(precision + 1);
  const conversionConstToSmallerUnit = Math.pow(10, precision);
  const smallerUnitNum = parseInt(
    (Number(numStrWithExtraPrecision) * conversionConstToSmallerUnit).toFixed(0),
  );
  const approxFunc = smallerUnitNum > 0 ? Math.floor : Math.ceil;
  return approxFunc(smallerUnitNum) / conversionConstToSmallerUnit;
};

/**
 * For USD: Cents To Dollar, (value / conversionUnits)
 */
export const convertMoneyFromStorageToUi = (
  moneyInStorage: string | number,
  locale = LOCALE as ELocale,
): number => {
  const valueInStr = convertMoneyFromStorageToUiStr(moneyInStorage, locale);
  return Number(valueInStr);
};

/**
 * For USD: Dollar to Cents,  (value * conversionUnits)
 */
export const convertMoneyFromUiToStorage = (
  moneyInUi: string | number,
  locale = LOCALE as ELocale,
): number => {
  const conversionUnits = getMoneyStorageConversionUnits(locale);
  return Math.round(Number(moneyInUi) * conversionUnits);
};

/**
 * For USD: Dollar to Cents,  (value * conversionUnits)
 */
export const convertMoneyFromUiToStorageByCurrencyCode = (
  moneyInUi: string | number,
  currencyCode: string,
): number => {
  const conversionUnits = getMoneyStorageConversionUnitsByCurrencyCode(currencyCode);
  return Math.round(Number(moneyInUi) * conversionUnits);
};

/**
 * For USD: Cents To Dollar, (value / conversionUnits)
 */
export const convertMoneyFromStorageToUiByCurrencyCode = (
  moneyInStorage: number,
  currencyCode: string,
): number => {
  const valueInStr = convertMoneyFromStorageToUiStrByCurrencyCode(
    moneyInStorage,
    currencyCode,
  );

  return Number(valueInStr);
};
