import React, { BaseSyntheticEvent, useCallback, useEffect } from 'react';
import { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import * as H from 'history';
import style from './EpSubsListNav.module.scss';
import { Card, Dropdown, Row } from 'react-bootstrap';
import {
  latestAddonSearchParamAtom,
  latestCouponSearchParamAtom,
  latestCustomerSearchParamAtom,
  latestEmailLogSearchParamAtom,
  latestInvoiceSearchParamAtom,
  latestPlanSearchParamAtom,
  latestSubscriptionSearchParamAtom,
  savedFilterListSelectorFamily,
  latestUserSearchParamAtom,
  useRefreshSavedFilterList,
  latestProductSearchParamAtom,
  latestOfferSearchParamAtom,
  latestTransactionSearchParamAtom,
} from 'src/recoil';
import { SetterOrUpdater, useRecoilState, useRecoilValueLoadable } from 'recoil';
import { EpIcon } from 'src/assets';
import { EpLinkText } from 'src/components/links/EpLinkText';
import {
  EpSubsSavedFilterSelector,
  TFilterSelectOption,
} from 'src/components/tables/EpFullFeaturedTable/Filters/EpSubsSavedFilterSelector';
import { toInitialUpperCase } from 'src/utilities/stringUtility';
import { EpSubsFilterCard } from 'src/components/tables/EpFullFeaturedTable/Filters/EpSubsFilterCard';
import {
  TEpSubsDBCustomerValueKeys,
  TEpSubsDBSubscriptionValueKeys,
  TEpSubsDBInvoiceValueKeys,
  TEpSubsDBPlanValueKeys,
  TEpSubsDBAddonValueKeys,
  TEpSubsDBCouponValueKeys,
  TEpSubsDBEmail_logValueKeys,
  TEpSubsSaveFilterRequestData,
  IEpSubsUserRolesKeys,
  TFilterData,
  TFilterEntry,
} from 'src/models';
import { disableButton, reEnableButton } from 'src/utilities/commonUtils';
import {
  getParamsFromSearch,
  TLocationRecord,
} from 'src/components/tables/EpFullFeaturedTable';
import { putNoty } from 'src/utilities/epSubsNoty';
import { RouteComponentProps } from 'react-router-dom';
import { FilterAccessLevel, FILTER_SOURCE, TFilterSource } from 'src/js/constants/subs';
import { genRemoveFilter, genSaveFilter, TSaveFilterFailedResponse } from 'src/services';
import {
  EpSubsEditFilterModal,
  TEpSubsEditFilterModalFormData,
} from 'src/components/tables/EpFullFeaturedTable/Filters/EpSubsEditFilterModal';
import { DEBUGGING } from 'src/config';
import EpSubsConfirmModal from 'src/components/modals/EpSubsConfirmModal';
import {
  EpSubsSimpleFilterSelector,
  TSimpleFilterSelectOption,
} from 'src/components/tables/EpFullFeaturedTable/Filters/EpSubsSimpleFilterSelector';
import {
  getConfirmRemoveFilterModalContext,
  getFilterData,
  getIdMatchedSavedFilterOption,
  getVerifiedFilterOptionAndUpdateData,
  getVerifiedSimpleFilterOptionAndUpdateData,
  getSimpleFilterData,
  UNTITLED_FILTER_TITLE,
} from './EpSubsListNavUtil';
import { EpSubsMultipleSelect } from 'src/components/dropdownSelect/EpSubsMultipleSelect';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { latestGatewaySearchParamAtom } from 'src/recoil/gateway/gatewayStates';
import { latestAdminBulkSearchParamAtom } from 'src/recoil/bulkOperation/adminBulkOperationStates';
import { latestWebhookSearchParamAtom } from 'src/recoil/webhook/webhookOperationStates';
import { onCreateGiftBtnClick } from 'src/containers/subscriptions/EpSubsSubscriptionUtils';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';

const FILTER_ALLOWED_SOURCE: TFilterSource[] = [
  FILTER_SOURCE.CUSTOMER,
  FILTER_SOURCE.SUBSCRIPTION,
  FILTER_SOURCE.INVOICE,
];

export const isFilterEnabledSearchList = (source: TFilterSource): boolean =>
  FILTER_ALLOWED_SOURCE.includes(source);

interface Props {
  routerProps: RouteComponentProps;
  createBtnText?: string;
  createGiftBtnText?: string;
  onCreateBtnClick?: (history: H.History) => void;
  onCreateGiftBtnClick?: (history: H.History) => void;
  history?: H.History;
  source?: TFilterSource;
  allSimplyFilterOptions?: { [key: string]: TSimpleFilterSelectOption[] };
  defaultSimplyFilterOption?: { [key: string]: string[] } | null;
}

export const getNoFilterOptionBySource = (
  source?: TFilterSource,
): TFilterSelectOption | undefined => {
  if (!source) return undefined;
  return {
    label: toInitialUpperCase(`All ${source}s`),
    value: '0',
    filterJson: JSON.stringify([{ field: `${source}.id`, operator: 'is present' }]),
    accessLevel: parseInt(FilterAccessLevel.PRIVATE),
    created_by: 0,
  };
};

/**
 * The NavBar for each List Page
 * @param createBtnText The text shown for the create button
 * @param onCreateBtnClick The callback function for the create button
 * @param source The filters are enabled for 'customer', 'subscription', 'invoice'.
 * @param defaultSimplyFilterOption (SimpleFilter Only) The default value for simple filters, default to null for elastic filters
 * @returns The NavBar JSX element itself
 */
const EpSubsListNav = ({
  routerProps,
  createBtnText,
  createGiftBtnText,
  onCreateBtnClick,
  history,
  source,
  allSimplyFilterOptions,
  defaultSimplyFilterOption = null,
}: Props): JSX.Element => {
  const refreshSavedFilterList = useRefreshSavedFilterList();
  const filterEnabled = source && isFilterEnabledSearchList(source);
  const noFilter = getNoFilterOptionBySource(source);
  // *********************************************** //
  //     Set search parameter atom for url change    //
  // *********************************************** //
  let sourceSearchParam: TLocationRecord;
  let setSourceSearchParam: SetterOrUpdater<TLocationRecord>;
  switch (source) {
    case FILTER_SOURCE.CUSTOMER:
      [sourceSearchParam, setSourceSearchParam] = useRecoilState(
        latestCustomerSearchParamAtom,
      );
      break;
    case FILTER_SOURCE.SUBSCRIPTION:
      [sourceSearchParam, setSourceSearchParam] = useRecoilState(
        latestSubscriptionSearchParamAtom,
      );
      break;
    case FILTER_SOURCE.INVOICE:
      [sourceSearchParam, setSourceSearchParam] = useRecoilState(
        latestInvoiceSearchParamAtom,
      );
      break;
    case FILTER_SOURCE.PRODUCT:
      [sourceSearchParam, setSourceSearchParam] = useRecoilState(
        latestProductSearchParamAtom,
      );
      break;
    case FILTER_SOURCE.PLAN:
      [sourceSearchParam, setSourceSearchParam] = useRecoilState(
        latestPlanSearchParamAtom,
      );
      break;
    case FILTER_SOURCE.OFFER:
      [sourceSearchParam, setSourceSearchParam] = useRecoilState(
        latestOfferSearchParamAtom,
      );
      break;
    case FILTER_SOURCE.ADDON:
      [sourceSearchParam, setSourceSearchParam] = useRecoilState(
        latestAddonSearchParamAtom,
      );
      break;
    case FILTER_SOURCE.COUPON:
      [sourceSearchParam, setSourceSearchParam] = useRecoilState(
        latestCouponSearchParamAtom,
      );
      break;
    case FILTER_SOURCE.EMAIL_LOG:
      [sourceSearchParam, setSourceSearchParam] = useRecoilState(
        latestEmailLogSearchParamAtom,
      );
      break;
    case FILTER_SOURCE.USER:
      [sourceSearchParam, setSourceSearchParam] = useRecoilState(
        latestUserSearchParamAtom,
      );
      break;
    case FILTER_SOURCE.GATEWAY:
      [sourceSearchParam, setSourceSearchParam] = useRecoilState(
        latestGatewaySearchParamAtom,
      );
      break;
    case FILTER_SOURCE.ADMIN_BULK:
      [sourceSearchParam, setSourceSearchParam] = useRecoilState(
        latestAdminBulkSearchParamAtom,
      );
      break;
    case FILTER_SOURCE.WEBHOOK:
      [sourceSearchParam, setSourceSearchParam] = useRecoilState(
        latestWebhookSearchParamAtom,
      );
      break;
    case FILTER_SOURCE.TRANSACTION:
      [sourceSearchParam, setSourceSearchParam] = useRecoilState(
        latestTransactionSearchParamAtom,
      );
      break;
    default:
      // When it is a page without filter enabled, sourceSearchParam will not be used in this component at all.
      break;
  }

  // *********************************************** //
  //          Initialize Filter States               //
  // *********************************************** //
  const [showUpdateOrCreateModal, setShowUpdateOrCreateModal] = useState(false);
  const [showConfirmRemoveModal, setShowConfirmRemoveModal] = useState(false);
  const [filterIdToRemove, setFilterIdToRemove] = useState<number | null>(null);
  const [savedFilterOptions, setSavedFilterOptions] = useState<
    TFilterSelectOption[] | null
  >(null);
  const [idMatchedSavedFilterOption, setIdMatchedSavedFilterOption] =
    useState<TFilterSelectOption | null>(null);
  const savedFilterListLoadable =
    !!source && filterEnabled
      ? useRecoilValueLoadable(savedFilterListSelectorFamily(source))
      : null;

  // Initialize SavedFilterLists
  useEffect(() => {
    if (
      filterEnabled &&
      !!savedFilterListLoadable &&
      savedFilterListLoadable.state === 'hasValue'
    ) {
      const options = savedFilterListLoadable.contents.map(obj => ({
        value: `${obj.id}`,
        filterJson: obj.filters,
        label:
          toInitialUpperCase(String(obj.filter_name)) +
          `${obj.access_level == 0 ? '(Private)' : ' '}`,
        groupName: obj.group_name,
        accessLevel: obj.access_level,
        created_by: Number(obj.created_by),
      }));
      // console.log('Filter Sort: ', [noFilter].concat(options).sort().reverse());
      noFilter && setSavedFilterOptions([noFilter].concat(options));
    }
  }, [filterEnabled, savedFilterListLoadable?.contents]);
  DEBUGGING &&
    console.log(
      'InitListNavInFilterSelector: Filter Dropdown: ',
      'savedFilterListLoadable?.contents',
      savedFilterListLoadable?.contents,
      'noFilter',
      noFilter,
      'savedFilterOptions',
      savedFilterOptions,
    );

  const [showFilterDetail, setShowFilterDetail] = useState(false);
  const [isEditedFilter, setIsEditedFilter] = useState(false);
  const [isAppliedFilter, setIsAppliedFilter] = useState(false);
  const [isSavedFilter, setIsSavedFilter] = useState(false);
  const [creatingNewFilter, setCreatingNewFilter] = useState(false);
  const [selectedFilterOption, setSelectedFilterOption] =
    useState<null | TFilterSelectOption>(null);
  const [selectedSimpleFilterOption, setSelectedSimpleFilterOption] = useState<null | {
    [key: string]: string[];
  }>(() => {
    DEBUGGING &&
      console.log(
        'Simple Filter: Check Initialization: useState',
        'history',
        history,
        'routerProps.location.search',
        routerProps.location.search,
        'allSimplyFilterOptions',
        allSimplyFilterOptions,
        'defaultSimplyFilterOption',
        defaultSimplyFilterOption,
      );
    if (history && allSimplyFilterOptions && defaultSimplyFilterOption) {
      const verifiedSimpleFilterOptionFromUrl =
        getVerifiedSimpleFilterOptionAndUpdateData({
          routerProps,
          history,
          setSourceSearchParam,
          allSimplyFilterOptions,
          defaultSimplyFilterOption,
        });
      return verifiedSimpleFilterOptionFromUrl;
    }
    return defaultSimplyFilterOption;
  });
  const [savedFilterData, setSavedFilterData] = useState<TFilterEntry[]>([]);

  // Initialize/Update ElasticSearch Filter Related States or Simple Filter Options
  useDeepCompareEffect(() => {
    if (filterEnabled) {
      if (
        history &&
        noFilter &&
        !!savedFilterOptions &&
        !!savedFilterListLoadable &&
        savedFilterListLoadable.state != 'loading'
      ) {
        try {
          const verifiedFilterOptionFromUrl = getVerifiedFilterOptionAndUpdateData({
            routerProps,
            history,
            setSourceSearchParam,
            noFilter,
            savedFilterOptions,
            onRenderingEditedFilter: () => {
              setShowFilterDetail(true);
              setIsEditedFilter(true);
            },
          });
          setSelectedFilterOption(verifiedFilterOptionFromUrl);
          setSavedFilterData(getFilterData(verifiedFilterOptionFromUrl));
          setIdMatchedSavedFilterOption(
            getIdMatchedSavedFilterOption({
              routerProps,
              noFilter,
              savedFilterOptions,
            }),
          );
        } catch (e) {
          const message = `Failed to initialize the initial Filter Option! ${
            (e as Error).message
          }`;
          putNoty({ type: 'error', text: message });
          return;
        }
      }
    } else {
      // Simple Filter or noFilter case
      DEBUGGING &&
        console.log(
          'Simple Filter: Check Initialization: UseEffect',
          'history',
          history,
          'routerProps.location.search',
          routerProps.location.search,
          'allSimplyFilterOptions',
          allSimplyFilterOptions,
          'defaultSimplyFilterOption',
          defaultSimplyFilterOption,
          'filterEnabled',
          filterEnabled,
        );
      if (history && allSimplyFilterOptions && defaultSimplyFilterOption) {
        const verifiedSimpleFilterOptionFromUrl =
          getVerifiedSimpleFilterOptionAndUpdateData({
            routerProps,
            history,
            setSourceSearchParam,
            allSimplyFilterOptions,
            defaultSimplyFilterOption,
          });
        setSelectedSimpleFilterOption(verifiedSimpleFilterOptionFromUrl);
      }
    }
  }, [
    filterEnabled,
    routerProps.location.search,
    // Fallback to bypass the useDeepCompareEffect detection of object in dependency array
    noFilter ?? {},
    savedFilterOptions ?? [],
    defaultSimplyFilterOption ?? {},
    allSimplyFilterOptions ?? {},
  ]);

  // *********************************************** //
  //      Define Simple Filter related Actions       //
  // *********************************************** //

  const onClickSimpleFilterOption = (option: TSimpleFilterSelectOption | null): void => {
    if (option === null) {
      const message = 'Failed to apply simple filter due to null value in option object!';
      putNoty({ type: 'error', text: message });
      return;
    }

    const routeParams = getParamsFromSearch<
      | TEpSubsDBPlanValueKeys
      | TEpSubsDBAddonValueKeys
      | TEpSubsDBCouponValueKeys
      | TEpSubsDBEmail_logValueKeys
      | IEpSubsUserRolesKeys
    >(sourceSearchParam.search as H.Search);
    const { order, sort, perPage, simpleQuery } = routeParams;
    const filter = decodeURIComponent(routeParams.filter);

    if (option.groupField) {
      const newFilterData = {
        ...getSimpleFilterData(filter),
        [option.groupField]: [option.value],
      };
      const newLocationRecord = {
        search: [
          `?order=${sort !== '' ? order : ''}`,
          `page=${1}`,
          `perPage=${perPage}`,
          `sort=${sort}`,
          `filter=${encodeURIComponent(JSON.stringify(newFilterData))}`,
          `simpleQuery=${simpleQuery}`,
        ].join('&'),
      };
      history && history.push(newLocationRecord);
      DEBUGGING && console.log('Apply Simple Filter: History Pushed', newLocationRecord);
      return;
    }
    const message = 'Invalid option without groupField!';
    putNoty({ type: 'error', text: message });
  };

  const onChangeSimpleFilterMultipleSelect = (
    filterKey: string,
    valueList: string[] | null,
  ): void => {
    if (valueList === null) {
      const message =
        'Failed to apply simple multi-value filter due to null value in valueList!';
      putNoty({ type: 'error', text: message });
      return;
    }
    DEBUGGING && console.log('EmailLog ListNav: Check MultiSelect Change:', valueList);
    const routeParams = getParamsFromSearch<TEpSubsDBEmail_logValueKeys>(
      sourceSearchParam.search as H.Search,
    );
    const { order, sort, perPage, simpleQuery } = routeParams;
    const filter = decodeURIComponent(routeParams.filter);

    if (filterKey) {
      const newFilterData = {
        ...getSimpleFilterData(filter),
        [filterKey]: valueList,
      };
      const newLocationRecord = {
        search: [
          `?order=${sort !== '' ? order : ''}`,
          `page=${1}`,
          `perPage=${perPage}`,
          `sort=${sort}`,
          `filter=${encodeURIComponent(JSON.stringify(newFilterData))}`,
          `simpleQuery=${simpleQuery}`,
        ].join('&'),
      };
      history && history.push(newLocationRecord);
      DEBUGGING && console.log('Apply Simple Filter: History Pushed', newLocationRecord);
      return;
    }
    const message = 'Invalid option without groupField!';
    putNoty({ type: 'error', text: message });
  };

  // *********************************************** //
  //          Define Filter related Actions          //
  // *********************************************** //

  const onClickSavedFilterOption = (option: TFilterSelectOption | null): void => {
    if (option === null) {
      const message = 'Failed to apply saved filter due to null value in option object!';
      putNoty({ type: 'error', text: message });
      return;
    }

    selectedFilterOption &&
      setIdMatchedSavedFilterOption({
        ...option,
        filterJson: option.filterJson,
      });
    // Apply the filter to refresh table data
    const filterId = parseInt(option.value);
    const routeParams = getParamsFromSearch<
      | TEpSubsDBCustomerValueKeys
      | TEpSubsDBSubscriptionValueKeys
      | TEpSubsDBInvoiceValueKeys
    >(sourceSearchParam.search as H.Search);
    const { order, sort, perPage, simpleQuery, simpleQueryFieldKey } = routeParams;

    const newLocationRecord = {
      search: [
        `?order=${sort !== '' ? order : ''}`,
        `page=${1}`,
        `perPage=${perPage}`,
        `sort=${sort}`,
        `filter=${encodeURIComponent(
          option.filterJson ??
            JSON.stringify([{ field: `${source}.id`, operator: 'is present' }]),
        )}`,
        `simpleQuery=${simpleQuery}`,
        `simpleQueryFieldKey=${simpleQueryFieldKey}`,
        `filterId=${filterId}`,
        `useFilteredSearch=${true}`,
        `useSavedFilter=${true}`,
      ].join('&'),
    };
    history && history.push(newLocationRecord);
    DEBUGGING && console.log('Apply Saved Filter: History Pushed', newLocationRecord);

    // Save the filter value to the filter card for further Edit
    const filterJsonData = option.filterJson;
    if (!filterJsonData) {
      const message =
        'Failed to apply saved filter to filter card due to missing filterJsonData in option object!';
      putNoty({ type: 'error', text: message });
      return;
    }
    const parsedFilterData = JSON.parse(filterJsonData);
    setSavedFilterData(parsedFilterData);
  };

  const onClickApplyFilters = (
    formData: Pick<TFilterData, 'filters'>,
    event?: BaseSyntheticEvent,
  ): boolean => {
    event && event.preventDefault();
    event && disableButton(event);
    const routeParams = getParamsFromSearch<
      | TEpSubsDBCustomerValueKeys
      | TEpSubsDBSubscriptionValueKeys
      | TEpSubsDBInvoiceValueKeys
    >(sourceSearchParam.search as H.Search);
    const { order, sort, perPage, simpleQuery, simpleQueryFieldKey, filterId } =
      routeParams;
    const filterJsonData = JSON.stringify(formData.filters);
    const newLocationRecord = {
      search: [
        `?order=${sort !== '' ? order : ''}`,
        `page=${1}`,
        `perPage=${perPage}`,
        `sort=${sort}`,
        `filter=${encodeURIComponent(filterJsonData)}`,
        `simpleQuery=${simpleQuery}`,
        `simpleQueryFieldKey=${simpleQueryFieldKey}`,
        // Allow Edit of a existing filter, and new filter can only be created when filterId = 0
        `filterId=${filterId}`,
        `useFilteredSearch=${true}`,
        `useSavedFilter=${false}`,
      ].join('&'),
    };
    history && history.push(newLocationRecord);
    DEBUGGING &&
      console.log('Apply customized Filter: History Pushed', newLocationRecord);

    setIsAppliedFilter(true);
    setSavedFilterData(formData.filters);
    event && reEnableButton(event);
    return false;
  };

  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [updateFilterResponseData, setUpdateFilterResponseData] =
    useState<TSaveFilterFailedResponse>();
  const [pendingUpdateFilterRequestDate, setPendingUpdateFilterRequestDate] =
    useState<TEpSubsSaveFilterRequestData>();
  const [pendingUpdateFilterId, setPendingUpdateFilterId] = useState<number>();

  const onUpdatingFilter = async (filterData: TFilterEntry[]): Promise<void> => {
    if (selectedFilterOption && source) {
      const filterJsonData = JSON.stringify(filterData);
      const filterId = parseInt(selectedFilterOption.value);
      const data: TEpSubsSaveFilterRequestData = {
        id: filterId > 0 ? filterId : undefined,
        filter_name: selectedFilterOption.label,
        filters: filterJsonData,
        access_level: selectedFilterOption.accessLevel,
      };
      setPendingUpdateFilterRequestDate(data);
      setPendingUpdateFilterId(filterId);
      try {
        const responseData = await genSaveFilter(source, data);
        if (typeof responseData === 'object') {
          setUpdateFilterResponseData(responseData);
          setShowConfirmModal(true);
          return;
        }
        // Update filter managing states
        refreshSavedFilterList(source);
        setIsEditedFilter(false);
        setIsAppliedFilter(false);
        setIsSavedFilter(true);
        setIdMatchedSavedFilterOption({
          ...selectedFilterOption,
          filterJson: filterJsonData,
        });

        //Update Url and SearchParaAtom
        const routeParams = getParamsFromSearch<
          | TEpSubsDBCustomerValueKeys
          | TEpSubsDBSubscriptionValueKeys
          | TEpSubsDBInvoiceValueKeys
        >(sourceSearchParam.search as H.Search);
        const { order, sort, perPage, simpleQuery, simpleQueryFieldKey } = routeParams;
        const newLocationRecord = {
          search: [
            `?order=${sort !== '' ? order : ''}`,
            `page=${1}`,
            `perPage=${perPage}`,
            `sort=${sort}`,
            `filter=${encodeURIComponent(filterJsonData)}`,
            `simpleQuery=${simpleQuery}`,
            `simpleQueryFieldKey=${simpleQueryFieldKey}`,
            `filterId=${filterId}`,
            `useFilteredSearch=${true}`,
            `useSavedFilter=${true}`,
          ].join('&'),
        };
        history && history.push(newLocationRecord);
        DEBUGGING &&
          console.log('Updated saved filter: History Pushed', newLocationRecord);
        const message = `Succeeded in updating filterId=${filterId}.`;
        putNoty({ type: 'success', text: message });
      } catch (e) {
        const message = `Failed to update filterId=${filterId}!!!`;
        putNoty({ type: 'error', text: message });
      }
    } else {
      const message = `selectedFilterOption is not ready when updating ${source} filter!!!`;
      putNoty({ type: 'error', text: message });
    }
  };

  const onConfirmToUpdateFilter = async (): Promise<void> => {
    if (source && pendingUpdateFilterRequestDate) {
      try {
        await genSaveFilter(source, pendingUpdateFilterRequestDate, true);
        const message = `Succeeded in updating filterId=${pendingUpdateFilterId}.`;
        putNoty({ type: 'success', text: message });
      } catch (e) {
        const message = `selectedFilterOption is not ready when updating ${source} filter!!!`;
        putNoty({ type: 'error', text: message });
      }
    }
  };

  const onRemovingFilter = async (filterId: number): Promise<void> => {
    try {
      await genRemoveFilter(filterId);

      // Update filter managing states
      source && refreshSavedFilterList(source);
      setShowConfirmRemoveModal(false);
      setFilterIdToRemove(null);

      if (
        noFilter &&
        selectedFilterOption &&
        selectedFilterOption.value === `${filterId}`
      ) {
        setIsEditedFilter(false);
        setIsAppliedFilter(false);
        setIsSavedFilter(true);
        setIdMatchedSavedFilterOption({
          ...noFilter,
        });
        //Update Url and SearchParaAtom
        const routeParams = getParamsFromSearch<
          | TEpSubsDBCustomerValueKeys
          | TEpSubsDBSubscriptionValueKeys
          | TEpSubsDBInvoiceValueKeys
        >(sourceSearchParam.search as H.Search);
        const { order, sort, perPage, simpleQuery, simpleQueryFieldKey } = routeParams;
        const newLocationRecord = {
          search: [
            `?order=${sort !== '' ? order : ''}`,
            `page=${1}`,
            `perPage=${perPage}`,
            `sort=${sort}`,
            `filter=${encodeURIComponent(noFilter.filterJson as string)}`,
            `simpleQuery=${simpleQuery}`,
            `simpleQueryFieldKey=${simpleQueryFieldKey}`,
            `filterId=${noFilter.value}`,
            `useFilteredSearch=${true}`,
            `useSavedFilter=${true}`,
          ].join('&'),
        };
        history && history.push(newLocationRecord);
        DEBUGGING &&
          console.log('Updated saved filter: History Pushed', newLocationRecord);
      }
      const message = `Succeeded in removing filterId=${filterId}.`;
      putNoty({ type: 'success', text: message });
    } catch (e) {
      const message = `Failed to remove filterId=${filterId}!!! ${(e as Error).message}`;
      putNoty({ type: 'error', text: message });
      setShowConfirmRemoveModal(false);
      setFilterIdToRemove(null);
    }
  };

  const editFilterModal = useCallback(() => {
    if (!selectedFilterOption) return <></>;
    DEBUGGING && console.log('Check EpSubsEditFilterModal input: ', selectedFilterOption);
    return (
      <EpSubsEditFilterModal
        modalShow={showUpdateOrCreateModal}
        title={creatingNewFilter ? 'Create New Filter' : 'Update Filter'}
        defaultFilterName={selectedFilterOption.label}
        defaultAccessLevel={`${selectedFilterOption.accessLevel}` as FilterAccessLevel}
        onClose={() => setShowUpdateOrCreateModal(false)}
        onSubmit={async (
          formData: TEpSubsEditFilterModalFormData,
          event?: BaseSyntheticEvent,
        ) => {
          event && event.preventDefault();
          event && disableButton(event);
          if (selectedFilterOption && source) {
            const filterJsonData = JSON.stringify(savedFilterData);
            const filterId = parseInt(selectedFilterOption.value);
            const data: TEpSubsSaveFilterRequestData = {
              id: filterId > 0 && !creatingNewFilter ? filterId : undefined,
              filter_name: formData.filter_name,
              filters: filterJsonData,
              // The form checkbox will always return a string value.
              access_level: Number(formData.access_level),
            };
            setPendingUpdateFilterRequestDate(data);
            setPendingUpdateFilterId(filterId);
            try {
              const responseData = await genSaveFilter(source, data);
              if (typeof responseData === 'object') {
                setUpdateFilterResponseData(responseData);
                setShowConfirmModal(true);
                return false;
              }
              const returnedFilterId = responseData;
              // Update filter managing states
              refreshSavedFilterList(source);
              setIsEditedFilter(false);
              setIsAppliedFilter(false);
              setIsSavedFilter(true);
              setCreatingNewFilter(false);
              // Used to tracking further filter change
              setIdMatchedSavedFilterOption({
                ...selectedFilterOption,
                filterJson: filterJsonData,
                value: `${returnedFilterId}`,
              });

              // Update Url and SearchParaAtom
              const routeParams = getParamsFromSearch<
                | TEpSubsDBCustomerValueKeys
                | TEpSubsDBSubscriptionValueKeys
                | TEpSubsDBInvoiceValueKeys
              >(sourceSearchParam.search as H.Search);
              const { order, sort, perPage, simpleQuery, simpleQueryFieldKey } =
                routeParams;
              const newLocationRecord = {
                search: [
                  `?order=${sort !== '' ? order : ''}`,
                  `page=${1}`,
                  `perPage=${perPage}`,
                  `sort=${sort}`,
                  `filter=${encodeURIComponent(filterJsonData)}`,
                  `simpleQuery=${simpleQuery}`,
                  `simpleQueryFieldKey=${simpleQueryFieldKey}`,
                  `filterId=${returnedFilterId}`,
                  `useFilteredSearch=${true}`,
                  `useSavedFilter=${true}`,
                ].join('&'),
              };
              history && history.push(newLocationRecord);
              DEBUGGING &&
                console.log('Updated saved filter: History Pushed', newLocationRecord);
              const message = `Succeeded in updating filterId=${filterId}.`;
              putNoty({ type: 'success', text: message });
              setShowUpdateOrCreateModal(false);
            } catch (e) {
              const message = `Failed to update filterId=${filterId}!!!`;
              putNoty({ type: 'error', text: message });
            }
          } else {
            const message = `selectedFilterOption is not ready when updating ${source} filter!!!`;
            putNoty({ type: 'error', text: message });
          }
          event && reEnableButton(event);
          return false;
        }}
      />
    );
  }, [
    showUpdateOrCreateModal,
    selectedFilterOption?.accessLevel,
    selectedFilterOption?.label,
    savedFilterData,
    source,
    creatingNewFilter,
  ]);

  // *********************************************** //
  //         Define Filter Dropdown Component        //
  // *********************************************** //
  let filterSelector: JSX.Element | false | undefined;
  switch (source) {
    // Generic Filter
    case FILTER_SOURCE.CUSTOMER:
    case FILTER_SOURCE.SUBSCRIPTION:
    case FILTER_SOURCE.INVOICE:
      DEBUGGING &&
        console.log(
          'Monitor Filter Card States In ListNav: ',
          `isEditedFilter: ${isEditedFilter}; `,
          `isAppliedFilter: ${isAppliedFilter}; `,
          `isSavedFilter: ${isSavedFilter}; `,
          `creatingNewFilter: ${creatingNewFilter}; `,
        );
      filterSelector = filterEnabled && !!selectedFilterOption && !!noFilter && (
        // Display Filter Dropdown only when it is enabled and loading is ready
        <Row>
          <Button
            id="showFilterCard"
            variant="blank"
            className={`p-1 ${style.square_button}`}
            style={{ margin: '10px' }}
            onClick={() => {
              setShowFilterDetail(!showFilterDetail);
              if (showFilterDetail) {
                setIsEditedFilter(false);
                setIsAppliedFilter(false);
                setIsSavedFilter(true);
              }
            }}
          >
            <EpIcon name={'filter'} className="p-0 m-0" style={{ height: '24px' }} />
          </Button>

          <div className={`${style.filter_dropdown}`} style={{ margin: '10px' }}>
            <EpSubsSavedFilterSelector
              onSelect={option => {
                setShowFilterDetail(false);
                setSelectedFilterOption(option);
                onClickSavedFilterOption(option);
                setIsEditedFilter(false);
                setIsAppliedFilter(false);
              }}
              selectedOption={{
                groupName: selectedFilterOption.groupName,
                // Determine what to display in the selected filter option
                label: `${
                  parseInt(selectedFilterOption.value) > 0 || !isEditedFilter
                    ? selectedFilterOption.label
                    : UNTITLED_FILTER_TITLE
                }${isEditedFilter && !isSavedFilter ? '*' : ''}`,
                value: selectedFilterOption.value,
                filterJson: selectedFilterOption.filterJson,
                accessLevel: selectedFilterOption.accessLevel,
                created_by: selectedFilterOption.created_by,
              }}
              savedFilterOptions={savedFilterOptions}
            />
          </div>

          <div style={{ margin: '10px', fontWeight: 'bold' }}>
            {!(selectedFilterOption.value !== '0') && (
              <EpLinkText
                onClick={() => {
                  setShowFilterDetail(true);
                  setCreatingNewFilter(true);
                  setSelectedFilterOption({ ...selectedFilterOption, value: '0' });
                  setIdMatchedSavedFilterOption(noFilter);
                }}
                className={'pt-2'}
              >
                {'Create New Filter'}
              </EpLinkText>
            )}
          </div>
        </Row>
      );
      break;
    // Simple Filter
    case FILTER_SOURCE.PLAN:
    case FILTER_SOURCE.ADDON:
    case FILTER_SOURCE.TRANSACTION:
    case FILTER_SOURCE.COUPON:
    case FILTER_SOURCE.USER:
      DEBUGGING &&
        console.log(
          'Simple Filter Dropdown Status Check: ',
          source,
          allSimplyFilterOptions,
          selectedSimpleFilterOption,
        );
      const filterKeys = Object.keys(allSimplyFilterOptions ?? {});
      filterSelector = (
        <Row className="mx-0">
          {allSimplyFilterOptions &&
            !!defaultSimplyFilterOption &&
            !!selectedSimpleFilterOption &&
            filterKeys.map(key => (
              <div
                className={`${
                  filterKeys.length === 1
                    ? style.simple_filter_dropdown
                    : style.multi_simple_filter_dropdown
                }`}
                key={key}
              >
                <EpSubsSimpleFilterSelector
                  onSelect={option => {
                    option?.value &&
                      setSelectedSimpleFilterOption({
                        ...selectedSimpleFilterOption,
                        [key]: [option.value],
                      });
                    onClickSimpleFilterOption(option);
                  }}
                  selectedOptionValue={
                    (selectedSimpleFilterOption[key] ?? defaultSimplyFilterOption[key])[0]
                  }
                  allOptions={allSimplyFilterOptions[key]}
                />
              </div>
            ))}
        </Row>
      );
      break;
    case FILTER_SOURCE.EMAIL_LOG:
      DEBUGGING &&
        console.log(
          'Check the displayed value of simple Multi select status',
          allSimplyFilterOptions &&
            !!selectedSimpleFilterOption &&
            selectedSimpleFilterOption[Object.keys(allSimplyFilterOptions)[0]],
          !!defaultSimplyFilterOption &&
            allSimplyFilterOptions &&
            defaultSimplyFilterOption[Object.keys(allSimplyFilterOptions)[0]],
        );
      filterSelector = (
        <Row className="mx-0">
          {allSimplyFilterOptions &&
            !!defaultSimplyFilterOption &&
            !!selectedSimpleFilterOption &&
            Object.keys(allSimplyFilterOptions).map(key => (
              <div className={`${style.multi_select_filter_dropdown}`} key={key}>
                <EpSubsMultipleSelect
                  placeholder={'All Email Logs'}
                  onChange={valueList => {
                    valueList != null &&
                      setSelectedSimpleFilterOption({
                        ...selectedSimpleFilterOption,
                        [key]: valueList,
                      });
                    onChangeSimpleFilterMultipleSelect(key, valueList ?? null);
                  }}
                  allOptions={allSimplyFilterOptions[key].map(option => {
                    return {
                      value: option.value,
                      label: option.label,
                      groupField: option.groupName,
                    };
                  })}
                  defaultValue={
                    selectedSimpleFilterOption[key] ?? defaultSimplyFilterOption[key]
                  }
                />
              </div>
            ))}
        </Row>
      );
      break;
  }

  return (
    <div style={{ marginBottom: '-1.5rem' }}>
      <Navbar
        id="list-nav"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          width: 'auto',
          paddingLeft: '50px',
          paddingRight: '10px',
        }}
      >
        <div>{filterSelector}</div>
        {/* Place Holder p-tag Here to Move the creation button to the right of the NavBar */}
        <div style={{ marginRight: '34px' }}>
          <div>
            {createGiftBtnText ? (
              <Dropdown style={{ paddingLeft: '0px' }}>
                <Dropdown.Toggle
                  variant="primary"
                  id="dropdown-basic"
                  style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
                >
                  {createBtnText}
                </Dropdown.Toggle>
                <DropdownMenu>
                  {onCreateBtnClick && history && (
                    <Dropdown.Item
                      id="Ep_create_new_sub"
                      onClick={() => onCreateBtnClick(history)}
                    >
                      {createBtnText}
                    </Dropdown.Item>
                  )}
                  {createGiftBtnText && history && (
                    <Dropdown.Item
                      id="Ep_create_gift_sub"
                      onClick={() => onCreateGiftBtnClick(history)}
                    >
                      {createGiftBtnText}
                    </Dropdown.Item>
                  )}
                </DropdownMenu>
              </Dropdown>
            ) : (
              createBtnText &&
              onCreateBtnClick &&
              history && (
                <Button
                  id="Ep_create_new"
                  onClick={() => onCreateBtnClick(history)}
                  style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
                  variant="primary"
                >
                  {createBtnText}
                </Button>
              )
            )}
          </div>
        </div>
      </Navbar>
      {showFilterDetail && filterEnabled && selectedFilterOption && noFilter && (
        <Card className={`p-4 ${style.filter_card}`}>
          <EpSubsFilterCard
            // source={source as TFilterSource}
            idMatchedSavedFilterOption={idMatchedSavedFilterOption}
            onSubmit={onClickApplyFilters}
            defaultFilterData={savedFilterData}
            // Filter Management Parameters
            isEditedFilter={isEditedFilter}
            isAppliedFilter={isAppliedFilter}
            isSavedFilter={isSavedFilter}
            onEditingFilter={() => setIsAppliedFilter(false)}
            onCheckingEditedFilter={(isEdited: boolean) => {
              setIsEditedFilter(isEdited);
              setIsSavedFilter(false);
            }}
            onCreatingOrEditingFilter={(
              filterData: TFilterEntry[],
              isCreatingNew = false,
            ) => {
              // setIsEditedFilter(false);
              // setIsAppliedFilter(false);
              // setIsSavedFilter(true);
              setShowUpdateOrCreateModal(true);
              setCreatingNewFilter(isCreatingNew);
            }}
            onUpdatingFilter={onUpdatingFilter}
            onRemovingFilter={(filterIdToRemove: number) => {
              setFilterIdToRemove(filterIdToRemove);
              setShowConfirmRemoveModal(true);
              setShowFilterDetail(false);
            }}
            onClearFilter={() => {
              onClickSavedFilterOption(noFilter);
              setSelectedFilterOption(noFilter);
              setIsEditedFilter(false);
              setIsAppliedFilter(false);
              setIsSavedFilter(true);
              setShowFilterDetail(false);
              localStorage.setItem('filterData', '');
            }}
            selectedFilterOption={selectedFilterOption}
          />
        </Card>
      )}
      {editFilterModal()}
      {filterIdToRemove && savedFilterOptions && (
        <EpSubsConfirmModal
          headerName={`Remove Filter ${filterIdToRemove}`}
          contextContent={getConfirmRemoveFilterModalContext(
            filterIdToRemove,
            savedFilterOptions,
          )}
          modalShow={showConfirmRemoveModal}
          onClose={() => setShowConfirmRemoveModal(false)}
          onConfirm={() => onRemovingFilter(filterIdToRemove)}
        />
      )}
      {updateFilterResponseData && (
        <EpSubsConfirmModal
          headerName={`Update Filter`}
          contextContent={updateFilterResponseData.Message}
          modalShow={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          onConfirm={() => onConfirmToUpdateFilter()}
        />
      )}
    </div>
  );
};

export default EpSubsListNav;
