import axios from 'axios';
import { IRecordOfflinePaymentFormDB } from 'src/containers/invoices/invoiceDetail/invoiceActions/EpSubsRecordOfflinePaymentForm';
import { IRecordRefundFormDB } from 'src/containers/invoices/invoiceDetail/invoiceActions/EpSubsRecordRefundForm';
import { IRefundFormData } from 'src/containers/invoices/invoiceDetail/invoiceActions/EpSubsRefundForm';
import { IChangeInvoiceAddressFormData } from 'src/containers/invoices/invoiceDetail/invoiceActions/EpSubsChangeAddressModal';
import { hostUrl } from '../config.js';
import {
  IEpSubsCompositeDBInvoiceValue,
  IEpSubsDBInvoiceValue,
  IEpSubsDBInvoice_activityValue,
  IEpSubsDBInvoice_commentValue,
  IEpSubsTagsListValue,
  TEpSubsDBInvoiceValueKeys,
  TEpSubsDBResponseOk,
} from '../models';
import FileDownload from 'js-file-download';
import {
  EMPTY_EXPORT_ERROR,
  getSortSQLStrFromSortingItems,
  TSortingItem,
} from './epSubsServiceUtils';
import { ICollectNowFormData } from '../containers/invoices/invoiceDetail/invoiceActions/EpSubsCollectNowForm';
import { IWriteOffVoidFormData } from '../containers/invoices/invoiceDetail/invoiceActions/EpSubsWriteOffVoidForm';
import { unixtimeWithoutTime } from 'src/components/formComponents/ESFormDateInput';
import { ITagsFormData } from 'src/components/modals/EpSubsTagModal.js';
import { IExcessPaymentFormData } from 'src/containers/invoices/invoiceDetail/invoiceActions/EpSubsExcessPaymentForm.js';
import { IClearDisputeFormData } from 'src/containers/invoices/invoiceDetail/invoiceActions/EpSubsClearDisputeForm.js';
import { putNoty } from 'src/utilities/epSubsNoty';

/**
 * Api call for invoice list
 * @returns response with total number
 */
export const genInvoiceListData = async (
  perPage = 10,
  pageIdx = 1,
  sortingItems: Array<TSortingItem<TEpSubsDBInvoiceValueKeys>> = [],
  simpleQuery = '',
): Promise<TEpSubsDBResponseOk<IEpSubsDBInvoiceValue>> => {
  const sortSQLStr =
    getSortSQLStrFromSortingItems<TEpSubsDBInvoiceValueKeys>(sortingItems);
  return axios
    .get(
      `${hostUrl}/invoices/search?` +
        [
          `key=${simpleQuery}`,
          `sort=${sortSQLStr}`,
          `per_page=${perPage}`,
          `page=${pageIdx - 1}`,
        ].join('&'),
    )
    .then(response => {
      return response.data;
    });
};

/**
 * Api call for exporting invoice list and generate a file download
 * @returns The exported filename
 */
export const genExportInvoiceList = async (
  sortingItems: Array<TSortingItem<TEpSubsDBInvoiceValueKeys>> = [],
  simpleQuery = '',
): Promise<string> => {
  const sortSQLStr =
    getSortSQLStrFromSortingItems<TEpSubsDBInvoiceValueKeys>(sortingItems);
  return axios
    .get(
      `${hostUrl}/invoices/export?` +
        [`key=${simpleQuery}`, `sort=${sortSQLStr}`].join('&'),
    )
    .then(response => {
      const payloadFileStr = response.data;
      if (Object.keys(payloadFileStr).length === 0) throw new Error(EMPTY_EXPORT_ERROR);
      const fileName = `INVOICE-${unixtimeWithoutTime(Date.now())}.csv`;
      FileDownload(payloadFileStr, fileName);
      return fileName;
    });
};

/**
 * Api call for invoice detail of given id
 * @returns response with the detail data
 */
export const genInvoiceData = async (
  id: number,
): Promise<IEpSubsCompositeDBInvoiceValue> => {
  return axios.get(`${hostUrl}/invoices/${id}`).then(response => {
    return response.data;
  });
};

export const genInvoiceActivityData = async (
  id: number,
  limit: number,
): Promise<IEpSubsDBInvoice_activityValue[]> => {
  return axios.get(`${hostUrl}/invoices/${id}/actlogs?limit=${limit}`).then(response => {
    return response.data.data;
  });
};

export const genInvoiceActivityDetail = async (
  actLogId: number,
): Promise<IEpSubsDBInvoice_activityValue> => {
  return axios.get(`${hostUrl}/invoices/actlogs/${actLogId}`).then(response => {
    return response.data;
  });
};

export const genInvoiceCommentData = async (
  id: number,
  limit?: number,
): Promise<IEpSubsDBInvoice_commentValue[]> => {
  return axios
    .get(`${hostUrl}/invoices/${id}/comments?${limit ? 'limit=' + limit : ''}`)
    .then(response => {
      return response.data.data;
    });
};

export const genCreateInvoiceComment = async (
  data: FormData,
  removeAllUploadedImage: () => void,
): Promise<string> => {
  return axios.post(`${hostUrl}/invoices/comments/add`, data).then(response => {
    removeAllUploadedImage();
    return response.data.data;
  });
};

/**
 * Api call for the list of invoices in the detail page of subscriptions or customers
 * @param source can be 'subscriptions' or 'customers'
 * @param id id of the source subscription or customer
 * @returns response
 */
export const genInvoices = async (
  id: number,
  source: 'subscriptions' | 'customers',
): Promise<TEpSubsDBResponseOk<IEpSubsDBInvoiceValue>> => {
  return axios.get(`${hostUrl}/${source}/${id}/invoices`).then(response => {
    return response.data;
  });
};

/**
 * Api call for online invoice refund
 * @param data from user
 * @returns response
 */
export const genOnlineInvoiceRefund = async (
  id: number,
  data: IRefundFormData,
): Promise<any> => {
  return axios.post(`${hostUrl}/invoices/${id}/refund`, data).then(response => {
    return response.data;
  });
};

export const genConfirmOnlineInvoiceRefund = async (
  id: number,
  data: IRefundFormData & { confirmed?: boolean },
): Promise<any> => {
  return axios.post(`${hostUrl}/invoices/${id}/refund`, data).then(response => {
    return response.data;
  });
};

/**
 * Api call for off-line invoice refund
 * @param data from user
 * @returns response
 */
export const genRecordInvoiceRefund = async (
  data: IRecordRefundFormDB,
): Promise<string> => {
  return axios
    .post(`${hostUrl}/invoices/${data.invoice_id}/offline-refund`, data)
    .then(response => {
      return response.data;
    });
};

export const genExcessPayment = async (
  id: number,
  data: IExcessPaymentFormData,
): Promise<boolean> => {
  return axios.post(`${hostUrl}/invoices/${id}/excess-payment`, data).then(response => {
    return response.data;
  });
};

export const genClearDispute = async (
  id: number,
  data: IClearDisputeFormData,
): Promise<string | { url?: string; returnUrl?: string }> => {
  return axios.post(`${hostUrl}/invoices/${id}/clear-dispute`, data).then(response => {
    if (response.data.code != undefined) {
      const message = `${response.data.message}`;
      putNoty({ type: 'error', text: message });
      const { url, return_url } = JSON.parse(response.data.data);
      return { url, returnUrl: return_url };
    } else {
      const clearDisputeResponse = response.data.data;
      return clearDisputeResponse;
    }
  });
};

export const genRecordOfflinePayment = async (
  data: IRecordOfflinePaymentFormDB,
): Promise<string> => {
  return axios
    .post(`${hostUrl}/invoices/${data.invoice_id}/offline-payment`, data)
    .then(response => {
      return response.data;
    });
};

export const genChangeInvoiceAddress = async (
  id: number,
  data: IChangeInvoiceAddressFormData,
): Promise<string> => {
  return axios.post(`${hostUrl}/invoices/${id}/change-address`, data).then(response => {
    return response.data;
  });
};

export const genSendEmailToUser = async (userId: number): Promise<string> => {
  return axios.post(`${hostUrl}/invoices/${userId}/send-email`).then(response => {
    return response.data.message;
  });
};

export const genSendTestEmail = async (
  notificationId: number,
  toEmail: string,
): Promise<string> => {
  return axios
    .get(`${hostUrl}/notifications/${notificationId}/sample/${toEmail}`)
    .then(response => {
      return response.data.message;
    });
};

export const genViewPDF = async (userId: number, token: string): Promise<any> => {
  return axios
    .get(`${hostUrl}/invoices/${userId}/view_pdf`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    })
    .then(response => {
      return response.data;
    });
};

export const genInvoiceCollectNow = async (
  id: number,
  data: ICollectNowFormData,
): Promise<string | { url?: string; returnUrl?: string }> => {
  return axios.post(`${hostUrl}/invoices/${id}/collect`, data).then(response => {
    if (response.data.code != undefined) {
      const { url, return_url } = JSON.parse(response.data.data);
      return { url, returnUrl: return_url };
    } else {
      const newId = response.data.data;
      return newId;
    }
  });
};

export const genWriteOffInvoice = async (
  id: number,
  data: IWriteOffVoidFormData,
): Promise<boolean> => {
  return axios.post(`${hostUrl}/invoices/${id}/write-off`, data).then(() => {
    return true;
  });
};

export const genVoidInvoice = async (
  id: number,
  data: IWriteOffVoidFormData,
): Promise<boolean> => {
  return axios.post(`${hostUrl}/invoices/${id}/void`, data).then(() => {
    return true;
  });
};

export const getInvoiceTags = async (): Promise<IEpSubsTagsListValue[]> => {
  return axios.get(`${hostUrl}/invoices/tags`).then(response => {
    return response.data ?? [];
  });
};

export const getInvoiceTagsById = async (id: number): Promise<IEpSubsTagsListValue[]> => {
  return axios.get(`${hostUrl}/invoices/${id}/tags`).then(response => {
    return response.data ?? [];
  });
};

export const editInvoiceTags = async (
  id: number,
  data: ITagsFormData,
): Promise<IEpSubsTagsListValue[]> => {
  return axios
    .patch(`${hostUrl}/invoices/${id}/tags`, JSON.stringify(data.tags.join(',')))
    .then(response => {
      return response.data ?? [];
    });
};

export const applyExistFunInvoice = async (id: number): Promise<boolean> => {
  return axios.post(`${hostUrl}/invoices/${id}/apply-existing-fund`).then(() => {
    return true;
  });
};

export const genPDFListApi = async (file_path: string, token: string): Promise<any> => {
  return axios
    .get(`${hostUrl}/${file_path}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    })
    .then(response => {
      return response.data;
    });
};

export const reminderFunctionApi1 = async (id: number): Promise<string> => {
  return axios
    .post(`${hostUrl}/invoices/${id}/send-dispute-reminder/1`)
    .then(response => {
      return response.data;
    });
};

export const reminderFunctionApi2 = async (id: number): Promise<string> => {
  return axios
    .post(`${hostUrl}/invoices/${id}/send-dispute-reminder/2`)
    .then(response => {
      return response.data;
    });
};

export const readInvoiceMessage = async (message_id: number): Promise<boolean> => {
  return axios.patch(`${hostUrl}/invoices/read-message/${message_id}`).then(response => {
    return response.data;
  });
};
